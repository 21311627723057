export const regexKit = /^(?=.{4,})(?=.*[0-9])(?=.*[A-Z])([A-Z0-9]+)$/;

export const otpErrorMessages = {
  wrong_otp_attempt_exceed: "Invalid OTP. Redirecting to login page",
  link_expired1: "The link has been expired",
  link_expired2: "The link has expired",
  invalid_otp: "Invalid OTP",
};

export const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

export const brainAgeType = [
  { label: "All", value: 0, text: "All" },
  { label: "Assessment", value: 1, text: "ASSESSMENT" },
  { label: "Training", value: 2, text: "TRAINING" },
];

export const SortArray = (arr = [], keyName = "", ...args) => {
  // console.log(args, keyName);
  if (!arr || !arr.length) return [];

  if (!keyName) {
    keyName = "flName";
  }

  try {
    const sortedArray = [...arr].sort((a, b) => {
      const nameA =
        keyName === "flName"
          ? `${a.first_name} ${a.last_name}`.toLowerCase().trim()
          : a?.[keyName]
            ? `${a[keyName]}`.trim()
            : "";

      const nameB =
        keyName === "flName"
          ? `${b.first_name} ${b.last_name}`.toLowerCase().trim()
          : b?.[keyName]
            ? `${b[keyName]}`.trim()
            : "";

      return nameA.localeCompare(nameB);
    });

    // console.log(sortedArray);
    return sortedArray;
  } catch (error) {
    throw error; // Let the caller handle the error
  }
};

export const nameRegex = /^[-a-zA-Z0-9.'\p{L}\s]{1,30}$/u;

export const usernameRegex = /^[a-zA-Z0-9._\p{L}\s-]*$/u;

export const genderList = [
  { label: "Female", key: 0, value: "female" },
  { label: "Male", key: 1, value: "male" }
];

export const languageList = [
  { label: "English", value: "en" },
  { label: "German", value: "de" },
  { label: "Lithuanian", value: "lt" },
  { label: "Dutch", value: "nl" },
  { label: "Polish", value: "pl" },
]
export const RESULT_TYPE_KIT_STATUS = [
  'RESULT_READY_DNA', 'RESULT_READY_EPI',
  'FAILED_QC_DNA', 'FAILED_QC_EPI'
]

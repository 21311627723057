import React from 'react';
import { Route } from 'react-router-dom';


function getBackgroundImage(partner) {
  if (partner == 7) {
    return "url(/images/biosynergyauth.jpg)"
  } 
  if (partner == 50) {
    return "url(/images/eternaLifeAuth.jpeg)"
  }
  if (partner == 48) {
    return "url(/images/lifeTimeAuth.svg)"
  }
  else {
    return "url(/images/authbg2.png)"
  }
}

const AuthLayout = ({ children }) => (
  <>
    <div className="signin-wrp">
      <div
        className="bgPart"
        style={{ backgroundImage: getBackgroundImage(children.props.partner) }}
      >
      </div>
      {children}
    </div>
  </>
);


const AuthLayoutRoute = ({ component: Component, partner: Partner, ...rest }) => {

  return (
    <Route {...rest} render={matchProps => (
      <AuthLayout>
        <Component {...matchProps} partner={Partner} />
      </AuthLayout>
    )} />
  )
};

export default AuthLayoutRoute;  

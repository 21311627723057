import React  from "react";
import { Table} from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import moment from "moment";


class ContentTable extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return (
            <div className="cmnTable">
                
                <Table responsive striped>
                    <thead>
                        <tr>
                            {this.props.questions && this.props.questions.length !==0 && <th>Questions</th>}
                            {this.props.questions && this.props.questions.answers&& this.props.questions.answers[0].length>0 &&this.props.questions.answers[0].map((ans,i) =>(
                                // i<=2 ?
                                (i==0 ? <th>Current Answers<br/>(<small>{moment(ans.createdAt).format("DD-MM-YYYY")}</small>)</th>
                                : <th>Previous Answers<br/>(<small>{moment(ans.createdAt).format("DD-MM-YYYY")}</small>)</th>)
                                 ))}
                               {this.props.questions &&this.props.questions.answers&&   this.props.questions.answers[0].length==0 && 
                                <th>Current Answers<small></small> </th>
                               } 
                               {/* {
                                   (this.props.questions === undefined || this.props.questions === []) && 
                                   <th>Current Answers<small></small> </th>
                               }      */}
                               {console.log("this",this.props.questions)}
                        </tr>
                    </thead>
                    {!this.props.loader && 
                    <tbody>
                        {this.props.questions && this.props.questions.key &&this.props.questions.key.map((k,i) =>(
                               <tr key={k}>
                                    <td>{this.props.questions.text[i]}</td>
                                    
                                    {this.props.questions.answers[i].length > 0 ?
                                        <>
                                            {this.props.questions.questionAnswer[i].map((ans, j) => {
                                                console.log("ans", ans, "index", i, j, ans.map(e => e.text).join(","))
                                                return (
                                                    <td> {ans.map(e => e.text).join(",")}</td>
                                                )
                                            })
                                            }
                                        </>
                                        :
                                        <>
                                            <td></td>
                                            <td></td>
                                        </>
                                    }
                                </tr>
                        ))}
                        
                    </tbody> }
                    
                </Table>
                {(!this.props.questions || this.props.questions.length ==0)&&!this.props.loader&&
                    <h5 style={{textAlign:'center',paddingTop:'20px'}}> {this.props.message}</h5>
                        }
                {this.props.loader && <Spinner className='spinnerClass' animation="border" variant="info"  style = {{marginLeft:'50%', padding:'20px',marginTop:'30px', marginBottom:'30px'}} />}
    
            </div>
        );
    }

}


export default ContentTable;

import React from "react";

// Import react-circular-progressbar module and styles
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Radial separators
import RadialSeparators from "./RadialSeparators";
import { connect } from "react-redux";
import { alertActions } from "../../../actions";

const PieChart = (props) => {

  let partner_id = props.user?.partner_id;
  let graph_color = "#09a79e";

  if(props.graphColor) { graph_color = props.graphColor} 
else { 
  if (partner_id == 25 || partner_id == 31 || partner_id == 33) {
    graph_color = "#6e2b8b";
  } else if (partner_id == 7) {
    graph_color = "#000000";
  } else if (partner_id == 26) {
    graph_color = "#398cb2";
  } else if (partner_id == 28) {
    graph_color = "#00CBB2";
  } else if (partner_id == 13) {
    graph_color = "#17629e";
  }else if(partner_id == 45){
    graph_color = '#080B37'
  }else if (partner_id === 46) {
    graph_color = '#2c2c2c'
  }else if (partner_id === 48) {
    graph_color = '#65C0B6'
  }else if (partner_id === 11) {
    graph_color = '#76D3CA'
 }
  else if (partner_id === 15) {
    graph_color = '#243144'
  }
 else if (partner_id === 50) {
  graph_color = '#05aaff'
}
else if (partner_id === 51) {
  graph_color = '#B34944'
}
}
  return (
    <div className="piechart-container" style={{ cursor: "pointer" }}>
      <div className="piechart-block">
    {/*  cognetial report pie */  }
        <div className={`${props.style === "congenital_report" ? 'col-lg-8 de-flex justify-content-center ml-lg-4 mr-xl-5' : ''}`}>
          <div
            className="piechart-block-div"
            style={{
              width: props.style === "congenital_report" ? "100%" : "80%"
            }}>

          <CircularProgressbarWithChildren
            value={parseInt(
              (props.num * 100) /
                (props.summary.total && props.summary.total.length == 2
                  ? props.summary.total[1]
                  : "")
            )}
            text={`${
              props.num +
              "/" +
              (props.summary.total && props.summary.total.length == 2
                ? props.summary.total[1] < props.num
                  ? props.num
                  : props.summary.total[1]
                : "")
            }`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: graph_color,
              trailColor: "#e6e6e6",
              textColor: graph_color,
              cursor: "pointer",
            })}
          >
            <RadialSeparators
              count={12}
              style={{
                background: "#fff",
                width: "2px",
                // This needs to be equal to props.strokeWidth
                height: `${10}%`,
              }}
            />
          </CircularProgressbarWithChildren>         
        </div>
      </div>
    </div>
    </div>
  );
};

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success,
};

export default connect(mapState, actionCreators)(PieChart);

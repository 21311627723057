import  React  from "react";
import moment from 'moment'
import DatePicker from "react-datepicker";
import { Modal, Form, Button, Table } from "react-bootstrap";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import SearchBillingBox from "../../components/SearchBox/SearchBillingBox";
import BillingTable from "../../components/Tables/BillingTable";
import Pagination from "../../components/Pagination/Pagination";
import {apiService} from '../../services/api_service'
import FilerButton from "../../components/UserMenu/FilerButton";
import FilterDropdown from "../Billing/dropdown"
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import { SortArray } from "../../constants";


let graph_color = '#09A79E'
let light_color = '#09a79e69'

class Billing extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            email: this.props.user.email,
            practitioner_id: this.props.user.id,
            partner_id: "",
            clinic_id: "",
            name: "",
            type: "",
            loading: false,
            request_loading: false,
            user_details: [],
            dropdown: [
                { label: 'Practitioner', value: 'practitioner' }
            ],
            addButton: false,
            select: "all",
            partner: [],
            clinic: [],
            practitioner: [],
            users: [],
            current_type: 'admin',
            /* Drop down */
            partnerList: [],
            subPartnerList: [],
            condition: {},

            /* Date Picker */
            startDate: moment().subtract(30, "days").toDate(),
            endDate: moment().toDate(),
            /* Checkbox */
            reportTypes: [
                { text: 'Practitioner Report', key: 'practitioner_report' },
                { text: 'Congenital Report', key: 'congenital_report' },
                { text: 'Disease Risk', key: 'disease_risk' },
                { text: 'Cognitive Domain', key: 'cognitive_domain' },
                { text: 'Cognitive Skill', key: 'cognitive_skill' },
                { text: 'Faceage Result', key: 'faceage_result' },
                { text: 'Face/Brain Subscription', key: 'face_brain_subscription' },
                { text: 'DNA12 Subscription', key: 'dna12_subscription' },
                { text: 'DNA Result', key: 'dna_result' },
                { text: 'Health Insight', key: 'health_insight' },
                { text: 'Lifestyle', key: 'lifestyle' },
                { text: 'Questionnaire', key: 'questionnaire' },
                { text: 'Practitioner Report Summary', key: 'practitioner_report_smmary' },
                { text: 'Congenital Report Summary', key: 'congenital_report_summary' },
                { text: 'Report Access', key: 'report_access' },
                { text: 'Steps Count', key: 'step_count' },
            ],
            reportChecked: [
                { text: 'Practitioner Report', key: 'practitioner_report' },
                { text: 'Congenital Report', key: 'congenital_report' },
            ],
            /* Table variables */
            reports: [],
            reportsTotal: 0,
            total: 0,
            pages: 1,
            limit: 10,
            skip: 0,

            /* Modal */
            showModal: false,
            kitData: [],

            request_access: false,
            search: "",
            filter: "",
            filter_type: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentWillReceiveProps(nextProps){
        if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
          this.setState({ request_loading: true });
          try {
            let user = nextProps.user;
            let type = user.type;
            let partner_id = user.partner_id;

            if (partner_id == 25 || partner_id == 31 || partner_id == 33) {
              graph_color = "#6e2b8b";
              light_color = "#84a864";
            } else if (partner_id == 7) {
              graph_color = "#000000";
              light_color = "#84a864";
            } else if (partner_id == 26) {
              graph_color = "#398cb2";
              light_color = "#84a864";
            } else if (partner_id == 28) {
              graph_color = "#00CBB2";
              light_color = "#84a864";
            } else if (partner_id == 13) {
              graph_color = "#17629e";
              light_color = "#84a864";
            } else if (partner_id == 45) {
                graph_color = "#000000";
                light_color = "#84a864";
            }else if (partner_id == 46) {
                graph_color = '#2c2c2c'
                light_color = '#8f96ef'
            }else if (partner_id === 11) {
                graph_color = '#65C0B6'
                light_color = '#a4dad4'
            }
            else if (partner_id === 15) {
                graph_color = '#243144'
                light_color = '#bcc9dc'
            }
            else if (partner_id === 50) {
                graph_color = '#05aaff'
                light_color = '#b3e6ff'
              }
              else if (partner_id === 51) {
                graph_color = '#B34944'
                light_color = '#f8eded'
              }
      
            let practitioner_details = nextProps.user;

            let pid = Buffer.from(String(practitioner_details._id)).toString(
              "base64"
            );
            await this.getUsageReport(pid);
            this.populateFilterValues(practitioner_details.type);
            await this.populateDropdownValues(type, pid);
            let user_details = [];

            this.setState({
              email: practitioner_details.email,
              practitioner_id: pid,
              partner_id: practitioner_details.partner_id,
              clinic_id: practitioner_details.clinic_id,
              name:
                practitioner_details.first_name +
                practitioner_details.last_name,
              type: practitioner_details.type,
              user_details,
              user_type: practitioner_details.type,
              current_type: practitioner_details.type,
              current_pract: practitioner_details._id,
              request_loading: false,
              ...nextProps.user
            });
          } catch (error) {
            console.log("error", error);
          }
        }
    }

    populateFilterValues (type) {
        let dropdown = []
        if (type === "clinic") {
            dropdown = [
                { label: 'All', value: 'all' },
                // { label: 'Admin', value: 'admin' },
                { label: 'Practitioner', value: 'practitioner' }
            ]
        } else if (type === "partner") {
            dropdown = [
                { label: 'All', value: 'all' },
                // { label: 'Admin', value: 'admin' },
                { label: 'Sub-Partner', value: 'clinic' },
                { label: 'Practitioner', value: 'practitioner' },
            ]
        } else if (type == "admin") {
            dropdown = [
                { label: 'All', value: 'all' },
                { label: 'Admin', value: 'admin' },
                { label: 'Partner', value: 'partner' },
                { label: 'Sub-Partner', value: 'clinic' },
                { label: 'Practitioner', value: 'practitioner' },
            ]
        }

        this.setState({dropdown});
    }

    /* Populate Dropdown filter values start */
    async populateDropdownValues(type, pid) {
        if (type === "admin") {
            console.log("admin")
            await this.partnerList({ practitioner_id: pid })
            await this.subPartnerList({ practitioner_id: pid })
        } else if (type === "partner") {
            console.log("partner")
            await this.subPartnerList({ practitioner_id: pid })
        }
    }

    async partnerList (condition){
        let partners = await apiService.PartnerList(condition)
        this.setState({partnerList:partners})
    }

    async subPartnerList (condition){
        let subPractitioners = await apiService.ClinicList(condition)
        this.setState({subPartnerList:subPractitioners})
    }
    /* Populate Dropdown filter values end */

    getReportTypes () {
        const { reportTypes, reportChecked } = this.state;
        let requestType = [];
        reportChecked.map((item)=>{
            requestType.push(item.key)
        })

        return requestType;
    }

    async getUsageReport(pid = undefined) {
        if (!pid) { pid = this.state.practitioner_id }
        console.log("getUsageReport", pid)
        const requestTypes = this.getReportTypes();
        const { startDate, endDate, condition, select, limit, skip, search } = this.state;
        console.log("condition in getreport", condition);

        let usageReport = await apiService.UsageReport(pid, startDate, endDate, requestTypes, condition, select, search);
        // console.log("usageReport", usageReport)
        let reportsTotal = usageReport.reduce((r, i) => r + parseInt(i.count), 0);

        /* Client side pagination. Change when implementing server side */
        let total = usageReport.length;
        let pages = [...Array(Math.ceil(total / limit)).keys()];
        let tableStart = skip > 0 ? ((skip) * limit) : 0,
            tableEnd = tableStart + limit;
        let tableData = usageReport.slice(tableStart, tableEnd);

        // console.log(tableStart, tableEnd, reports, tableData, search)
        this.setState({
            loading: false,
            reports: tableData,
            reportsTotal,
            total,
            pages
        })
    }

    handleChangeStart(date) {
        const { endDate } = this.state;

        if (date > endDate) {
            alert("end date should be greater than start date");
            return;
        }
        this.setState({ startDate: date });
    }
  
    handleChangeEnd(date) {
        const { startDate } = this.state;

        if (date < startDate) {
            alert("end date should be greater than start date");
            return;
        }
        this.setState({ endDate: date });

    }

    handleSubmit () {
        const { startDate, endDate, reportChecked } = this.state;
        /* Validation Start */
        if(startDate > endDate) {
            alert("end date should be greater than start date");
            return;
        }

        /* Validation End */

        // Call API to get updated results
        this.getUsageReport(this.state.practitioner_id);
    }

    async reportTypeCheckboxChange (value) {
        this.setState({reportChecked:value})
    }
   
    async showKits(index) {
        const { reports, reportTypes } = this.state;
        const userData = reports[index].data;

        let kitData = [];
        userData.map(u => {
            kitData.push({
                name: u.user ? u.user.first_name + " " + u.user.last_name : "Deleted user",
                email: u.user ? u.user.email : "Deleted user",
                kit: u.user ? u.user.kit_id : "Deleted user",
                // gender: u.user[0].gender,
                reportType: reportTypes.find(r => r.key === u.request_type).text,
                details:u.extra? camelize(u.extra):"",
                date: moment(u.date).format("DD-MM-YYYY")
            });
        })
        // console.log("kitData", kitData);
        this.setState({ showModal: true, kitData });
    }
    handleClose = () => this.setState({ showModal: false }) 

    downloadCsvFile() {
        const { kitData } = this.state;
        //define the heading for each row of the data
        let csv = 'Name, Email, Kit ID, Report Type, Details, Date\n';
        
        //merge the data with CSV
        kitData.forEach(function (row) {
            let rowData = [
                row.name,
                row.email,
                row.kit,
                row.reportType,
                row.details,
                row.date
            ];
            csv += rowData.join(',');
            csv += "\n";
        });
     
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        
        //provide the name for the CSV file to be downloaded
        hiddenElement.download = 'Kit Details.csv';
        hiddenElement.click();
    }

    async updateStatus (updated_user){
        updated_user.loading = true
        this.setState({
            data: this.state.user_details.map(el => (el.id === updated_user.id ? updated_user : el))
          });
        let userId = Buffer.from(String(updated_user._id)).toString('base64')
        let requestAccess = await apiService.requestAccess(userId)
        if(requestAccess.code === 200) { 
            updated_user.report_access_status ='Request Pending'
            updated_user.is_accepted = false
        }
        updated_user.loading = false
        this.setState({
            data: this.state.user_details.map(el => (el.id === updated_user.id ? updated_user : el))
          });

    }

    async handleFilter () {
        let partner = await apiService.userPractPartner(this.state.email, this.state.select)
        let clinic = await apiService.userPractClinic(this.state.email, this.state.select)
        this.setState({partner: partner, clinic})
    }

    async updateUsersList(userList, search){
        console.log("updateUserList", userList, search)
        // let total = userList[0].total.length>0? userList[0].total[0].count: 0
        // let partnerTblPages = [...Array(Math.ceil(total/this.state.limit)).keys()];
        // let user_details = userList[0].users
        // this.setState({user_details:user_details, total, partnerTblPages, partnerTblSkip: 0, search})
    }

    handleFilterSelect(value) {
        this.setState({ select: value }, () => this.getUsageReport(this.state.practitioner_id))
    }
    handleAccessChanges(checked){
        let modal = this.state.modal
        modal.access_controls = checked;
        this.setState({accessChecked: checked, modal })
    }

    async searchTable(searchValue) {
        try {
            this.setState({ search: searchValue }, async () => {
                await this.getUsageReport(this.state.practitioner_id)
            });

        } catch (error) {
            console.log("error", error)
        }
    }

    async selectHandler(e) {
        let type = e.target.name
        let value = e.target.value;
        let condition = {};

        // Get clinic list on partner select
        if (type == 'partner') {
            if (value != 'select') {
                await this.subPartnerList({ practitioner_id: btoa(this.state.current_pract), partner_id: btoa(value) })
            } else {
                await this.subPartnerList({ practitioner_id: btoa(this.state.current_pract) })
            }
        }

        if (value != 'select') {
            let pid = Buffer.from(String(value)).toString('base64');
            condition.practitioner_id = pid
            condition.type = type
        }
        console.log("condition in selectHandler", condition);
               
        // this.updateTable(condition, value)
        this.setState({condition: condition}, () => {
            this.getUsageReport();
        });
    }

    async handlePagination(skip) {
        this.setState({ loading: true, skip: skip }, () => {
            this.getUsageReport()
        })
    }

    render() {
        const { current_type, dropdown, showModal, kitData, startDate, endDate, 
            reports, reportsTotal, total, pages, skip, limit, select, partnerList, subPartnerList } = this.state

        return (
            <>
                <div className="billing-wrp">
                    <div className="billinghead">
                        <div className="row justify-content-between align-items-end">
                            <div className="col-12 col-sm-auto">
                                <h1>Billing</h1>
                                <BreadCrumb title="Billing"></BreadCrumb>
                            </div>
                            <div className="col-12 col-sm-auto d-flex">

                                <FilerButton types={dropdown} updateList={this.handleFilterSelect.bind(this)}></FilerButton>{ }
                                <SearchBillingBox email={this.state.email} limit={this.state.limit} skip={this.state.skip} searchTable={this.searchTable.bind(this)}></SearchBillingBox>
                            </div>
                        </div>

                    </div>
                    {/* <Form style={{ display: "flex" }} onSubmit={() => this.handleSubmit()}> */}
                        <div style={{ display: "flex" }}>
                        {/* Date Picker Start */}
                        <Form.Group controlId="formStartDate" style={{ marginRight: "10px" }}>
                            <Form.Label>Select Start Date</Form.Label>
                            <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={(e) => this.handleChangeStart(e)}
                                onClickOutside={this.openDatePicker}
                                maxDate={new Date()}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                            ></DatePicker>
                        </Form.Group>
                        
                        <Form.Group controlId="formEndDate" style={{ marginRight: "10px" }}>
                            <Form.Label>Select End Date</Form.Label>
                            <DatePicker
                                className="form-control"
                                selected={endDate}
                                onChange={(e) => this.handleChangeEnd(e)}
                                onClickOutside={this.openDatePicker}
                                maxDate={new Date()}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                            ></DatePicker>
                        </Form.Group>
                        {/* Date Picker End */}
                        
                        <Form.Group controlId="formCheckBox" style={{ marginRight: "10px", marginTop: "31px"}}>
                            <FilterDropdown
                                reportTypeCheckboxChange={this.reportTypeCheckboxChange.bind(this)}
                                reportTypes={this.state.reportTypes}
                                reportChecked={this.state.reportChecked}
                            ></FilterDropdown>
                            <Form.Text className="text-muted">
                                        {this.state.reportChecked.length==0? "Please check atleast one checkbox": null}
                                    </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formSubmitBtn" style={{ marginTop: "30px", marginRight: "10px" }}>
                            <Button onClick={() => this.handleSubmit()} className="dropdownBtn">Submit</Button>
                        </Form.Group>
                    </div>
                    {/* Filter start */}

                    <div className="innerpagesFiltration">

                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-sm-auto">
                                <ul className="list-unstyled-um">
                                    {current_type == 'admin' &&
                                        <li key={"partner"}><h6>Partner : </h6>  <Form.Group>
                                            <Form.Control as="select" name='partner' onChange={this.selectHandler.bind(this)}>
                                                <option key="sp2" value='select'>--Select Partner--</option>
                                                {partnerList && SortArray(partnerList,"first_name").map(user => (
                                                    <option key={user._id} value={user._id}>{user.first_name}</option>)
                                                )}
                                            </Form.Control>
                                        </Form.Group>  </li>
                                    }
                                    {(current_type == 'admin' || current_type == 'partner') &&
                                        <li key={"clinic"}><h6>Sub-partner : </h6>   <Form.Group>
                                            <Form.Control as="select" name='clinic' onChange={this.selectHandler.bind(this)}>
                                                <option key="sc2" value='select'>--Select Sub-partner--</option>
                                                {subPartnerList && SortArray(subPartnerList,"first_name").map(user => (
                                                    <option key={user.id} value={user._id}>{user.first_name}</option>)
                                                )}
                                            </Form.Control>
                                        </Form.Group>  </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Filter end */}
                    {/* Table Start */}
                    <div style={{ padding: "10px"}}> Showing {select} results</div>
                    <div className="col-12 col-lg-12">
                        <div className="report-table">
                            <BillingTable
                                loading_status={this.state.loading}
                                userDetails={reports}
                                showKits= {this.showKits.bind(this)}
                            ></BillingTable>
                        </div>
                        <div style={{ padding: "10px" }}> Total reports: {reportsTotal}</div>
                        <div className="d-flex justify-content-end">
                            <Pagination paginationClick={(num) => this.handlePagination(num)} total={total} pages={pages} skip={skip} limit={limit}></Pagination>
                        </div>
                    </div>
                    {/* Table End */}

                    <Modal show={showModal}  onHide={this.handleClose} centered className="billingKitModal" >
                        <Modal.Header closeButton>
                            <Modal.Title>Kit Details: </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th>Email</th>
                                        <th>Kit Id</th>
                                        <th>Request Type</th>
                                        <th>Details</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        kitData.map((each, index) => (
                                            <tr key={each.kit + index}>
                                                <td>{each.name}</td>
                                                <td>{each.email}</td>
                                                <td>{each.kit}</td>
                                                <td>{each.reportType}</td>
                                                <td>{each.details}</td>
                                                <td>{each.date}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <Button className="show-kits" onClick={() => this.downloadCsvFile()}> Download CSV</Button>
                        </Modal.Body>
                    </Modal>

                </div>
            </>
        )
    }
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toUpperCase();
    })
    .replace(/\s+/g, " ");
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return {loggingIn, user};
  }

const actionCreators = {
    success: alertActions.success
  }

export default connect(mapState, actionCreators)(Billing)



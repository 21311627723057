import React from "react";
import { Table, Modal } from "react-bootstrap";
import ColorIndicator from "../../components/ColorIndicator/ReverseColorIndicator";
import SelectionsBar from "../../components/SelectionsBar/SelectionsBar";
import PageHead from "../../components/PageHead/PageHead";
import { apiService } from '../../services/api_service'
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import DOMPurify from 'dompurify';

class Dna12Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showGeneModal: false,
      users_list: [],
      loader: false,
      message: "Please select patients from the above dropdown",
      htmlMessage: "",
      dnaResult: [],
      practitioner_id: this.props.user?.id,
      email: this.props.user?.email,
      viewData: {},
      fullResult: [],
      sections: [],
      access: {},
      currentIndex: '',
      clearUser: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleShowGeneModal = this.handleShowGeneModal.bind(this);
    this.handleCloseGeneModal = this.handleCloseGeneModal.bind(this);
  }
  handleClose() {
    this.setState({ show: false });
  }
  handleCloseGeneModal() {
    this.setState({ showGeneModal: false });
  }
  handleShow(result) {
    this.setState({ show: true, viewData: result });
  }

  handleShowGeneModal(result) {
    this.setState({ showGeneModal: true, viewData: result });

  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      try {
        this.setState({ loader: true });
        const practitioner_id = btoa(nextProps.user._id);
        //let users_list = await apiService.AccessUsersList(practitioner_id);
        this.setState({ practitioner_id: nextProps.user._id, loader: false, ...nextProps.user });
      } catch (error) {
        console.log("error", error);
      } finally {
        this.setState({ loader: false });
      }
    }
  }

  async getResult(user_id) {
    if (!this.props.user?._id || !user_id) return

    this.setState({ loader: true });
    if (user_id == "select") {
      this.setState({
        loader: false,
        message: "Please select patients from the above dropdown",
        dnaResult: [],
        fullResult: [],
      });
      return;
    }
    // const practitioner_id = btoa(this.props.user?._id);
    //console.log(this.state.practitioner_id);
    let DNA_result = await apiService.getDna12Result(btoa(user_id));
    let modes = []
    if (!DNA_result.success) {
      if (DNA_result.message == "user not found") {
        this.setState({ clearUser: true, loader: false });
      } else {
        const sanitizedMessage = DOMPurify.sanitize(DNA_result.message);
        this.setState({ htmlMessage: sanitizedMessage, loader: false })
      }
      return;
    } else {
      this.setState({ clearUser: false });
      modes = DNA_result.data.modes
      DNA_result = DNA_result.data.results;
    }


    if (DNA_result && DNA_result.length > 0) {
      let sections = this.getSections(modes)
      let currentIndex = sections[0].value.toUpperCase()
      this.setState({
        loader: false,
        currentIndex,
        dnaResult: DNA_result[0][currentIndex],
        fullResult: DNA_result,
        sections,
        message: "",
        htmlMessage: ""
      });
    } else {
      this.setState({
        loader: false,
        message: "DNA12 results not found for this user",
        dnaResult: [],
        fullResult: [],
        // sections
        // access: access,
      });
    }
  }

  getSections(result_sections) {
    const sections = result_sections.map((s) => {
      return { value: s.key, label: s.name }
    })
    // this.setState({ sections })
    return sections
  }
  async selectedItemResult(index) {
    index = index.toUpperCase()
    // this.setState({ currentIndex: index });
    // let selectedResult = this.state.fullResult[index.toUpperCase()]
    const { fullResult } = this.state;
    let filteredResult = fullResult.filter(item => Object.keys(item).includes(index));
    let selectedResult = filteredResult.length > 0 ? filteredResult[0][index] : []

    if (selectedResult.length > 0) {
      this.setState({ dnaResult: selectedResult, currentIndex: index });
    } else {
      this.setState({
        dnaResult: [],
        loader: false,
        message: "Results for this section is not available",
      })

    }
  }

  render() {
    const {
      show, showGeneModal,
      users_list,
      dnaResult,
      viewData,
      sections,
      message,
      htmlMessage,
      clearUser,
    } = this.state;
    return (
      <>
        <div className="dnaReport-wrp">
          <PageHead
            title="DNA12 Report"
            UserList={users_list}
            page={"DNA12_Report"}
            clearUser={clearUser}
            DetailsGetAPI={this.getResult.bind(this)}
          ></PageHead>

          <SelectionsBar
            list={sections}
            title={"DNA12 Results"}
            changeItem={this.selectedItemResult.bind(this)}
          ></SelectionsBar>

          <div className="DnaReporttable">
            {/* <ContentTable></ContentTable> */}
            {/* use this "ContentTable" table component with below hard coded table data */}

            <div className="cmnTable">
              {this.state.loader ? (
                <Spinner
                  animation="border"
                  variant="info"
                  className="spinnerClass"
                  style={{
                    marginLeft: "50%",
                    padding: "20px",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              ) : dnaResult.length > 0 ? (
                <Table responsive striped>
                  <thead>
                    <tr style={{ color: "white" }}>
                      <th>Title</th>
                      <th>Indicator</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dnaResult.map((result) => (

                      <tr key={result.category_key}>
                        <td>{camelize(result.title)}</td>
                        <td>
                          <ColorIndicator
                            num={result.output.indicator}
                          ></ColorIndicator>
                        </td>
                        <td style={{ fontWeight: "600" }}>
                          {camelize(result.status)}
                        </td>
                        <td>
                          <a
                            onClick={() => this.handleShow(result)}
                            style={{ cursor: "pointer" }}
                          >
                            View Result
                          </a>
                          <a
                            className="ml-3"
                            onClick={() => this.handleShowGeneModal(result)}
                            style={{ cursor: "pointer" }}
                          >
                            View Genotypes
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (!htmlMessage &&
                <h5 style={{ textAlign: "center", padding: "20px" }}>
                  {message}
                </h5>
              )}
              {htmlMessage &&
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                  dangerouslySetInnerHTML={{ __html: htmlMessage }} />
              }
            </div>
          </div>
        </div>
        {/* Results Modal */}
        {viewData.title && (
          <Modal
            show={show}
            onHide={this.handleClose}
            centered
            className="cmnModal dnareportModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {camelize(viewData.title)}
                <span className="risktype" style={{ color: "#fc1504" }}>
                  {camelize(viewData.status)}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: viewData.output.intro }}></div>
              <hr></hr>
              <h6>Result</h6>
              <div
                dangerouslySetInnerHTML={{ __html: viewData.output.result }}
              ></div>
              <h6>References</h6>
              <div>
                <table>
                  {viewData.references.map((ref) => (
                    <tr><a href={ref}>{ref}</a></tr>
                  ))}
                </table>
              </div>
            </Modal.Body>
          </Modal >
        )
        }

        {/* Genes Modal */}
        {viewData.title && (
          <Modal
            show={showGeneModal}
            onHide={this.handleCloseGeneModal}
            centered
            className="cmnModal genotypeModal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {camelize(viewData.title)}
                {/* <span className="risktype" style={{ color: "#fc1504" }}>
                  {camelize(viewData.status)}
                </span> */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <h6>Genes of interest</h6>
              <div>
                <Table bordered striped>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th>Gene</th>
                      <th>Allele</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewData.genotypes.map((g) => (
                      <tr style={{ textAlign: 'center' }}>
                        <td>{g.gene}</td>
                        <td>{g.allele}</td>
                        <td>{g.text}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
          </Modal >
        )
        }
      </>
    );
  }
}

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g, ' ');
}

function mapState(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const actionCreators = {
  success: alertActions.success
}

export default connect(mapState, actionCreators)(Dna12Result)


import React, { useState } from "react";
import PageHead from "../../components/PageHead/PageHead";
import TabItems from "../../components/TabItems/TabItems";
import ReportSummary from "./ReportSummary";
import ReportDetails from "./ReportDetails";
import OverviewDetails from "./OverviewDetails";
import { apiService } from '../../services/api_service';
import Select from 'react-select';
import { Spinner, Modal } from "react-bootstrap";
import { alertActions, currentActions } from "../../actions";
import WarningIntro from "./warningIntro";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";


class PractitionersReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: false,
      users_list: [],
      report_list: [],
      report: [],
      report_sum: 0,
      summary: [],
      currentUser: null,
      message: 'Please select patients from the above dropdown',
      tabs: [],
      practitioner_id: this.props.user?.id,
      category: '',
      subCategory: '',
      select: { label: 'Overview', value: 1 },
      error_display: false,
      dropdown: [
        { label: 'Overview', value: 1 },
        { label: 'Details', value: 2 },
        { label: 'Summary', value: 3 }
      ],
      access: {},
      overview: [],
      overview_head: [],
      tab_groups: [],
      showSelect: false,
      show: true,
      intro: true,
      title: "",
      clearUser: false
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      this.setState({ loader: true });
      try {
        // let intro = false;
        // let user = nextProps.user;
        let practitioner_details = nextProps.user//await apiService.practitionerDetails();
        // let user_details = await apiService.AccessUsersList(
        //   Buffer.from(String(practitioner_details._id)).toString("base64")
        // );
        // if (user.email) {
        //   intro = true;
        // }

        this.setState({
          //users_list: user_details,
          practitioner_id: Buffer.from(
            String(practitioner_details._id)
          ).toString("base64"),
          access: practitioner_details.access_controls.Practitioner_report,
          loader: false,
          //intro,
          //show: true,
          ...nextProps.user
        });
      } catch (error) {
        console.log("error", error);
      }
    }

  }

  async GetTabGroups(user_id) {
    if (!this.props.user?._id || !user_id) return
    try {
      this.setState({ loader: true })
      if (user_id == 'select') {
        this.setState({ loader: false, tab_groups: [], tabs: [], message: 'Please select patients from the above dropdown', currentUser: null, error_display: false })
        return

      }
      let result = await apiService.practionerPortalTabGroup(
        btoa(user_id),
      );
      if (result.length > 0) {

        console.log("tab group result", result);
        let tabs = result.map(t => t.title);
        console.log("tab group", tabs);

        // Get the first tab data
        this.setState({
          tab_groups: result,
          tabs,
          loader: false,
          currentUser: user_id,
        }, () => {
          this.getOverview(result[0]);
        });


      }
    } catch (error) {
      this.setState({ loader: false })
      console.log("error", error)
    }
  }

  async getOverview(tab) {
    this.setState({ loader: true })

    const { currentUser, practitioner_id } = this.state;
    const user_id = Buffer.from(String(currentUser)).toString('base64');
    let children = tab.children;
    const data = tab.children.map(c => c.key);

    let overview = await apiService.OverViewPRGetNew(practitioner_id, user_id, data)
    if (!overview.success) {
      let message = overview.message || 'No Results found'
      if (overview.status == 500)
        message = "Error in generating report"

      this.setState({
        clearUser: true,
        message,
        error_display: true,
        loader: false
      })
      return;
    } else {
      this.setState({ clearUser: false })
      overview = overview.data;
    }
    if (overview && overview.length > 0) {
      let availableTabs = overview.map(o => o.type)
      children = children.filter(ch => availableTabs.includes(ch.key))
      this.setState({
        category: tab.title,
        subCategory: '',
        overview,
        report_list: children,
        showSelect: false,
        select: { label: 'Overview', value: 1 },
        error_display: false,
        loader: false,
        title: tab.title.replace("_", " ").toLocaleUpperCase()
      });
    } else {
      this.setState({
        loader: false,
        message: "User doesn't have any result",
        error_display: true
      });
    }

  }
  async getPractitionerReport(category) {
    if (category == "gut_inflammation")
      category = "gut_health"

    const { practitioner_id, currentUser } = this.state;
    let result = await apiService.practionerPortal(practitioner_id, currentUser, category)
    if (result.data) {
      let summary_num = result.data.reduce(function (sum, item) {
        if (item.value.num !== undefined && item.value.num !== null) {
          return sum + item.value.num
        } else {
          return sum + 0
        }

      }, 0)
      let summary = await apiService.ReportSummary(practitioner_id, category, summary_num)
      console.log("summary", summary)
      let sample = result.data.map((item) => {
        switch (item.value?.num) {
          case 0:
            item.num = 5
            break;
          case 1:
            item.num = 3
            break;
          case 2:
            item.num = 1
            break;
          default:
            item.num = 1
        }
      })
      this.setState({ report: result.data, report_sum: summary_num, error_display: false, summary })
    } else {
      this.setState({ message: "User doesn't have any result", error_display: true, report: [], summary: [] })
    }

  }

  async changeCategory(category) {
    const { tab_groups } = this.state;
    let selectedTab = tab_groups.find(t => t.title == category);

    if (selectedTab.children && selectedTab.children.length) {
      await this.getOverview(selectedTab);
    }
  }

  async selectSubCategory(subCategory) {
    this.setState({ loader: true })
    await this.getPractitionerReport(subCategory.key);
    this.setState({
      showSelect: true,
      select: { label: 'Summary', value: 3 },
      subCategory: subCategory.key,
      loader: false,
      title: subCategory.key.replace("_", " ").toLocaleUpperCase()
    })
  }

  handleSelect(selected) {
    console.log("value", selected);
    let updateState = { select: selected };
    if (selected.value == 1) {
      updateState.showSelect = false;
    }
    this.setState(updateState);
  }

  async handleSuccess() {
    this.props.removeCurrentUser()
    setTimeout(() => {
      this.setState({ intro: false, show: false });
    }, 0);
  }

  async handleMoreInfo() {
    window.open("https://www.muhdo.com/practitionersreport", '_blank', 'noopener,noreferrer');
  }

  handleClose() {
    const { history } = this.props;
    if (history) history.goBack();
  }
  handleTitle() {
    this.setState({ title: "" })
  }


  render() {
    const { loader, tabs, users_list, report_list, category, subCategory,
      report, dropdown, select, message, report_sum, currentUser,
      error_display, summary, overview, showSelect, show, title, clearUser } = this.state;
    const intro = (
      <Modal show={show} centered className="trackingModal" size="sm">
        <Modal.Header >
          <Modal.Title>WARNING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WarningIntro></WarningIntro>
          <div className="row">
            <div className="col-12 col-md-8 mt-4">
            {(this.state.partner_id !== 50 && this.state.partner_id !== 51) ? 
                <button className="infobtn-view" onClick={this.handleViewExample}>View Example Report</button>
              : ''}
            </div>
            <div className="col-12 col-md-4 text-end mt-4">
              <button className={"continuebtn-yes width-100px d-inline m-0"} onClick={() => this.handleSuccess()}>Yes</button>&nbsp;
              <button className={"continuebtn-no width-100px d-inline m-0"} onClick={() => this.handleClose()}>No</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
    return (
      <>
        <div className="dnaReport-wrp">
          {this.state.intro ? intro : null}
          {!this.state.intro && !this.state.show && (
            <PageHead
              title="Practitioner Report"
              UserList={users_list}
              DetailsGetAPI={this.GetTabGroups.bind(this)}
              page={"EPI"}
              clearUser={clearUser}
              handleTitle={this.handleTitle.bind(this)}
            ></PageHead>
          )}

          <TabItems
            tab={tabs}
            categorySelector={this.changeCategory.bind(this)}
            category={category.replace(/_/g, " ")}
          ></TabItems>
          {/* <SelectionsBar></SelectionsBar> */}
          {/* use this "SelectionsBar" component with below hard coded data */}
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>
                  {/* <img src="/images/immunity.svg"></img>  */}
                  {title ? title.replace(/_/g, " ") : "Practitioner Report"}
                </h3>
              </div>
              {showSelect ? (
                <div className="col-12 col-sm-auto">
                  <Select
                    onChange={(res) => this.handleSelect(res)}
                    defaultValue={dropdown[0]}
                    value={select}
                    options={dropdown}
                    menuPlacement="auto"
                    isSearchable={false}
                    className="customSelect-wrp"
                    classNamePrefix="customSelect"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="practitionerReport">
            {loader ? (
              <Spinner
                className="spinnerClass"
                animation="border"
                variant="info"
                style={{
                  marginLeft: "50%",
                  padding: "20px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />
            ) : currentUser && !error_display ? (
              select.value === 3 ? (
                <ReportSummary
                  num={report_sum}
                  data={report}
                  summary={summary}
                />
              ) : select.value === 2 ? (
                <ReportDetails data={report} />
              ) : (
                <OverviewDetails
                  overview={overview}
                  tabs={report_list}
                  categorySelector={this.selectSubCategory.bind(this)}
                />
              )
            ) : (
              <h5 style={{ textAlign: "center", padding: "20px" }}>
                {message}
              </h5>
            )}
          </div>
        </div>
      </>
    );
  }


}


function mapStateToProps(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const mapDispatchToProps = {
  success: alertActions.success,
  addCurrentUser: currentActions.add,
  removeCurrentUser: currentActions.remove
};

// withRouter(connect(mapStateToProps, mapDispatchToAction)(App));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(PractitionersReport);

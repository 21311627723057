import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Loader from "../../../components/Loader/Loader";

import { userActions, alertActions } from '../../../actions';
import { apiService } from "../../../services/api_service";
import { validatePasswordCriteria } from "../../../helpers/password";

class ChangePassword extends React.Component {
    constructor(props){
      super(props)
      this.props.logout(this.props.history);

      this.state = {
          email: "",
          newpassword: "",
          confirmpassword: "",
          submitted: false,
          hide: true,
          loading: false,
          emailError: false,
          passwordError: false,
          passwordFormat: false,
          hide1:true,
          confirmError: false,
          token : ""
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleKeyPress = this.handleKeyPress.bind(this);
      this.handleUnhidePassword = this.handleUnhidePassword.bind(this);
      this.handleUnhideConfirmPass = this.handleUnhideConfirmPass.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
  }

  componentWillMount(){
    this.setState({loading: true})
  }

  async componentDidMount(){
    let partner_id = this.props.partner
    // set default color
    if (partner_id == '7') {
        document.getElementById("bodyID").style.setProperty('--themecolor', '#000000');
        document.getElementById("bodyID").style.setProperty('--themefontcolor', '#000000');
        document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#a6a6a6');
        document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#e6e6e6');
        document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#818181');
        document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    }if (partner_id == '45') {
          document.getElementById("bodyID").style.setProperty('--themecolor', '#080B37');
          document.getElementById("bodyID").style.setProperty('--themefontcolor', '#080B37');
          document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#636ce9');
          document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#d2d4f9');
          document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#3642e2');
          document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
    }
          else if (partner_id === 46) {
            document
              .getElementById("bodyID")
              .style.setProperty("--themecolor", "#2c2c2c");
            document
              .getElementById("bodyID")
              .style.setProperty("--themefontcolor", "#2c2c2c");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteThemecolor", "#b3b3b3");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteBackgroundColor", "#f2f2f2");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteButtonBackground", "#e6e6e6");
            document
              .getElementById("bodyID")
              .style.setProperty("--navtext", "#ffffff");
            document
              .getElementById("bodyID")
              .style.setProperty("--headpadding", "22px 0px");
          }
          else if (partner_id === 48) {
            document
              .getElementById("bodyID")
              .style.setProperty("--themecolor", "#65C0B6");
            document
              .getElementById("bodyID")
              .style.setProperty("--themefontcolor", "#65C0B6");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteThemecolor", "#c9e9e5");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteBackgroundColor", "#edf8f6");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteButtonBackground", "#b7e1dc");
            document
              .getElementById("bodyID")
              .style.setProperty("--headpadding", "9px 0px");
           }
              else if (partner_id === 11) {
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themecolor", "#76D3CA");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themefontcolor", "#76D3CA");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteThemecolor", "#c5ece8");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteBackgroundColor", "#ecf9f7");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteButtonBackground", "#d8f3f0");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--headpadding", "22px 0px");
        } else if (partner_id === 15) {
          document
            .getElementById("bodyID")
            .style.setProperty("--themecolor", "#243144");
          document
            .getElementById("bodyID")
            .style.setProperty("--themefontcolor", "#243144");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteThemecolor", "#bcc9dc");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteBackgroundColor", "#eef1f6");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteButtonBackground", "#cdd6e5");
          document
            .getElementById("bodyID")
            .style.setProperty("--headpadding", "12px 0px");
        } 
        else if (partner_id === 50) {
          document
            .getElementById("bodyID")
            .style.setProperty("--themecolor", "#05aaff");
          document
            .getElementById("bodyID")
            .style.setProperty("--themefontcolor", "#05aaff");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteThemecolor", "#b3e6ff");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteBackgroundColor", "#cceeff");
            document
            .getElementById("bodyID")
            .style.setProperty("--navtext", "#ffffff");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteButtonBackground", "#cdd6e5");
          document
            .getElementById("bodyID")
            .style.setProperty("--headpadding", "15px 0px");
        }
        else if (partner_id === 51) {
          document
            .getElementById("bodyID")
            .style.setProperty("--themecolor", "#b34944");
          document
            .getElementById("bodyID")
            .style.setProperty("--themefontcolor", "#b34944");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteThemecolor", "#f8eded");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteBackgroundColor", "#f8eded");
            document
            .getElementById("bodyID")
            .style.setProperty("--navtext", "#ffffff");
          document
            .getElementById("bodyID")
            .style.setProperty("--liteButtonBackground", "#FCFCFA");
         
        }
        else {
        document.getElementById("bodyID").style.setProperty('--themecolor', '#09a79e');
        document.getElementById("bodyID").style.setProperty('--themefontcolor', '#09a79e');
        document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#09a79e69');
        document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#09a79e17');
        document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#0ac2b9');
        document.getElementById("bodyID").style.setProperty('--filterSvg', '');
    }

      if(!this.props.match.params.token){
         this.props.history.push("/signin");
      }
      
    let token = decodeURIComponent(this.props.match.params.token)

    if (!token) {
      this.props.history.push("/linkexpired")
    } else {
      let validate_token = await apiService.TokenValidate({
        authorizationToken: `Bearer ${token}`,
      });

      if (!validate_token) {
        this.props.error("Session Expired");
        this.props.history.push("/linkexpired")
      }
      this.setState({ token, email: validate_token?.data?.email || "", loading: false });
    }
   
    
  }

  componentDidUpdate(){
    if(this.props.alert.message){
      console.log(this.props.alert);
        setTimeout(()=>{
          this.props.clearAlerts();
        }, 5000)
    }
}


  handlePasswordValidation(name, value){
    let data = {}
    if(name==="newpassword")
    {
      const error = validatePasswordCriteria(value);
      data["passwordFormat"]= error.length 
    }

    if(name==="confirmpassword" )
    {
      data["confirmError"] = this.state.newpassword !== value
    }

    return Object.keys(data).length ? data : null
  }

  handleChange(e){
      const {name, value} = e.target;
      let data = { [name]: value }

      let validatePasswordData = this.handlePasswordValidation(name, value)
    
      if(validatePasswordData){
        data = {...data, ...validatePasswordData}
      }

      this.setState({ ...data });
  }

  handleKeyPress(e){
    const {name, value} = e.target;

    let validatePasswordData = this.handlePasswordValidation(name, value)
  
    if(validatePasswordData){
      this.setState({...validatePasswordData});
    }

    if(e.charCode === 13){
      if(this.state.newpassword && this.state.confirmpassword && !this.state.passwordFormat){
        e.preventDefault()
        this.handleSubmit()
      }else{
        if(!this.state.newpassword ){
            this.setState({passwordError: true })
        }else{
          if(!this.state.confirmpassword){
            this.setState({passwordError: false, confirmError: true })
          }
        }
      }
    }
  }

  async handleSubmit(){
    const {
      submitted , passwordError , passwordFormat , newpassword , confirmpassword
    } = this.state

    if(submitted || passwordError || passwordFormat || !newpassword || !confirmpassword || 
      (newpassword.length && confirmpassword.length && newpassword !==confirmpassword)
      ){
      return
    }

    if(this.state.newpassword === this.state.confirmpassword && !this.state.passwordFormat){
      this.setState({ submitted: true });
      const {email, newpassword } = this.state;
      if(email && newpassword){
          let result = await this.props.resetPassword(this.props.history, email, btoa(newpassword),this.props.partner,
            this.state.token
          )
       
          if (result?.statusCode === 400) {
            const choose_different_password =
              "Please use a different password, This one was used in the past.";
            
            if (result.message === choose_different_password) {
              this.props.error(result?.message || choose_different_password);
              this.setState({ submitted: false });
              return;
            }

            this.props.history.push("/linkexpired");
            return;
          }
          
          console.log("result", result)
          this.props.success("Successfully updated the password")
      }
    }else{
      if(this.state.confirmpassword){
        this.setState({ confirmError: true})
      }else{
        this.setState({passwordError: true})
      }
      
    }
  }
  handleUnhidePassword(){
    this.setState({hide: !this.state.hide})
  }
  handleUnhideConfirmPass(){
    this.setState({hide1: !this.state.hide1})
  }
  handleClose = () => {
      this.props.clearAlerts();
  }
  render(){
    const {partner,alert } = this.props;
    const { email, newpassword, confirmpassword, submitted, loading, hide, emailError, passwordError,confirmError, passwordFormat,hide1 } = this.state;
    

    return (
      <div className="formPart forgotForm">
        {alert.message &&
            <div className={`alert ${alert.type}`} style={{marginBottom: 0}}>
                {alert.message}
                <button class="close" onClick={this.handleClose} >x</button>
            </div>
        }
        <Loader loading={loading} />
        <h1>Change Password</h1>
        <h6>Your Password has been expired, Please Change your Password</h6>
        <Form>
        <Form.Group className="sign-field">
            <Form.Control placeholder="Email" type="text" name="email" value={email} disabled required />
            <Form.Text className="text-muted">
                {emailError? "Please Enter a valid Email": null}
            </Form.Text>
        </Form.Group>
        <Form.Group className="sign-field">
            <div className="passwordfeild">
                <Form.Control placeholder="New Password" type={this.state.hide?"password": "text"} name="newpassword" value={newpassword} onChange={this.handleChange} onKeyPress={this.handleKeyPress} required />
                <span className="eyeicon" onClick={this.handleUnhidePassword}>
                    <img style={{width: 'inherit'}} className="filterit" src={hide?"/images/eyeicon.svg":"/images/eye.svg"}></img>
                </span>
            </div>
            <Form.Text className="text-muted">
                {passwordError? "Please Enter the Password": null}
                {passwordFormat? "Must contain at least one number and one uppercase and lowercase letter and 15 characters and at least two special character": null}
            </Form.Text>
        </Form.Group> 
        <Form.Group className="sign-field">
            <div className="passwordfeild">
                <Form.Control placeholder="Confirm Password" type={this.state.hide1?"password": "text"} name="confirmpassword" value={confirmpassword} onChange={this.handleChange} onKeyPress={this.handleKeyPress} required />
                <span className="eyeicon" onClick={this.handleUnhideConfirmPass}>
                    <img className="filterit" src={hide1?"/images/eyeicon.svg":"/images/eye.svg"}></img>
                </span>
            </div>
            <Form.Text className="text-muted">
                {confirmError? "Password Didn't match": null}
            </Form.Text>
        </Form.Group> 
          <Button className="continuebtn" onClick={this.handleSubmit}
          disabled={submitted || passwordError || passwordFormat || !newpassword || !confirmpassword
            || (newpassword.length && confirmpassword.length && newpassword !==confirmpassword)
          }
          >
            Change Password
        </Button>  
          {partner == '7' ?
            <Link className="backtologin" to="/bio-synergy/signin">Back to Log In</Link>
            : partner == '50' ? 
              <Link className="backtologin" to="/eterna-life/signin">Back to Log In</Link>
             :
             partner == '48' ? 
             <Link className="backtologin" to="/lifetime-health/signin">Back to Log In</Link>
            :
            <Link className="backtologin" to="/signin">Back to Log In</Link>
          }
        </Form>
      </div>
    );
  }
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {loggingIn, alert};
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  success: alertActions.success,
  error: alertActions.error,
  resetPassword: userActions.resetPassword,
  login: userActions.login,
  logout: userActions.logout
}
export default connect(mapState, actionCreators)(ChangePassword);

import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile
  } from "react-icons/md";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
  let graph_color = '#09A79E'
  let light_color = '#09a79e69'

class MultiSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
            checked: [],
            expanded: [],
            list: [],
            output: [],
            access: this.props.access
        }
    }

    componentDidMount(){
        let partner_id = this.props.user?.partner_id
        if (partner_id == 25 || partner_id == 31 || partner_id == 33) {
            graph_color = '#6e2b8b'
            light_color = '#84a864'
        } else if (partner_id == 7) {
            graph_color = '#000000'
            light_color = '#84a864'
        } else if (partner_id == 26) {
            graph_color = '#398cb2'
            light_color = '#84a864'
        } else if (partner_id == 28) {
            graph_color = '#00CBB2'
            light_color = '#84a864'
        } else if (partner_id == 13) {
            graph_color = '#17629e'
            light_color = '#84a864'
        }
        else if (partner_id == 13) {
            graph_color = '#080B37'
            light_color = '#636ce9'
        }
        else if (partner_id == 45) {
            graph_color = '#080B37'
            light_color = '#636ce9'
        }
        else if (partner_id == 46) {
            graph_color = '#2c2c2c'
            light_color = '#8f96ef'
        }
        else if (partner_id === 48) {
            graph_color = '#65C0B6'
            light_color = '#a4dad4'
        }else if (partner_id === 11) {
            graph_color = '#76D3CA'
            light_color = '#c5ece8'
        }
        else if (partner_id === 15) {
            graph_color = '#243144'
            light_color = '#bcc9dc'
        }
        else if (partner_id === 50) {
            graph_color = '#05aaff'
            light_color = '#b3e6ff'
        }
        else if (partner_id === 51) {
            graph_color = '#B34944'
            light_color = '#f8eded'
          }
  
        this.setAccess()
    }

    componentDidUpdate(nextProps) {
        if (nextProps.access !== this.props.access) {
            this.setState({ access: this.props.access }, () => {
                this.setAccess()
            });
        }
    }

      setAccess () {
        let list = [], checked = [], output = {}
        this.state.access.map((items, ind) =>{
            list.push(items)
            let selected = {}
            if(this.props.disabled){
                // list[ind]["disabled"] = true
            }
            if(items.children && items.children.length > 0){
                items.children.map((item, index) => {
                    selected[item.value] = item.condition
                    if(this.props.disabled){
                        list[ind].children[index]["disabled"] = true
                    }
                    if(item.condition == true){
                        checked.push(item.value)
                    }
                })
            }else{
                delete items.children
                // items.children = []
                selected = items.condition
                if(items.condition == true){
                    checked.push(items.value)
                }
            }
            output[items.value] = selected
        })
        this.setState({list, checked, output})
      }
    onChecked (check){
        let selected = {}, output = this.state.output, list = this.state.list

        list.map((items, index)=>{
            if(items.children && items.children.length > 0){
                selected = output[items.value]
                items.children.map((item) => {
                    if(check.indexOf(item.value) >= 0) {
                        selected[item.value] = true
                        item.condition = true
                    } else {
                        selected[item.value] = false
                        item.condition = false
                    }
                    // check.map((value)=>{
                    //     if(item.value == value){
                    //         selected[item.value] = true
                    //         item.condition = true
                    //     }
                    // })
                })
            }else{
                selected = false

                if(check.indexOf(items.value) >= 0) {
                    selected = true;
                }
                // check.map((value)=>{
                //     if(items.value == value){
                //         selected = true
                //     }
                // })
            }
            output[items.value] = selected  
        })
        
        this.setState({checked: check ,list, output})
        this.props.handleAccessChanges(this.state.output)
    }
    
    render(){
        const { disabled} = this.props
        const { access, list } = this.state
        let size = 25
        let color = graph_color
        const icons = {
            check: <MdCheckBox className="rct-icon rct-icon-check" size={size} color={color} />,
            uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" size={size} color={color} />,
            halfCheck: (
              <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" size={size} color={color} />
            ),
            expandClose: (
              <MdChevronRight className="rct-icon rct-icon-expand-close" size={size} color={color} />
            ),
            expandOpen: (
              <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" size={size} color={color} />
            ),
            expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" size={size} color={color} />,
            collapseAll: (
              <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" size={size} color={color} />
            ),
            parentClose: <MdFolder className="rct-icon rct-icon-parent-close" size={size} color={color} />,
            parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" size={size} color={color} />,
            leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" size={size} color={color} />
          };
        return (
            <>
                <CheckboxTree
                    nodes={list}
                    checked={this.state.checked}
                    expanded={this.state.expanded}
                    onCheck={checked => this.onChecked(checked)}
                    onExpand={expanded => this.setState({ expanded })}
                    icons={icons}
                    disabled={disabled}
                />
            </>
        )
    }
    

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return {loggingIn, user};
  }

const actionCreators = {
    success: alertActions.success
  }

export default connect(mapState, actionCreators)(MultiSelect)


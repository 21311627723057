import config from '../config/config';
import { alertActions, userActions } from "../actions";
import { store } from '../helpers';
import { userService } from './user-service';

export const apiService = {
    practitionerDetails,
    kitHistory,
    requestAccess,
    search,
    questionnaireAnswer,
    practionerPortal,
    practionerPortalTab,
    practionerPortalTabGroup,
    CongenitalPortal,
    CongenitalPortalTab,
    CongenitalPortalTabGroup,
    CongenitalReportSummary,
    DNAResult,
    HealthInsightsResult,
    EpigeneticResult,
    FaceScanResult,
    userManagementGet,
    AccessUsersList,
    AccessUserslistByUserData,
    CreateUser,
    EmailValidator,
    KitIdValidator,
    BloodKitIdValidator,
    CheckAssignBloodKitStatus,
    assignBloodKitToUser,
    CreateBloodKit,
    BulkUploadBloodKit,
    GetBloodResult,
    ListUserByType,
    UpdateUserDetails,
    DeleteUser,
    createManagementUser,
    viewManagementUser,
    deleteManagementUser,
    editManagementUser,
    accessManagementUser,
    practitionerList,
    ClinicList,
    PartnerList,
    FilterdUserList,
    userPractList,
    userPractSearch,
    userPractPartner,
    userPractClinic,
    getUserList,
    getBloodUserList,
    assignUser,
    userManagementGetByFilter,
    releasePDFs,
    releaseBloodPdf,
    releaseDNA12ReportPDF,
    validateUMEmail,
    resendUMEmail,
    lifeStyleData,
    PartnerListNew,
    PartnerListNewNIN,
    TokenValidate,
    departmentList,
    departmentListSingle,
    departmentCreate,
    departmentUpdate,
    departmentRemove,
    departmentSearch,
    ReportSummary,
    UsersCount,
    ageRange,
    productCount,
    BengagementDevice,
    BengagementActive,
    BIEngagementPlatformUsers,
    BIEngagementActive,
    BIEngagementSession,
    TrackingCount,
    lifestyleCount,
    departmentListAPI,
    PractitionerIntro,
    ManagementITabs,
    ManagementIGet,
    ManagementIGetAll,
    ManagementIGetSingle,
    GAPlatformUsers,
    GAActiveEngadement,
    OverViewPRGet,
    questionScore,
    bioAge,
    OverViewPRGetNew,
    OverViewCongenitalGet,
    VirusRisk,
    createQuestionnaire,
    UserResendEmail,
    StepCount,
    QuestionCountWithDate,
    UsageReport,
    saveQuestionnaireAnswer,
    getAppAllowedPartners,
    enableAppAccess,
    getCountries,
    verifyResetLink,
    userSetPassword,
    completeUserSignup,
    GetAllUsers,
    getAvailablePartners,
    switchPartner,
    AddChildKitToExistingUser,
    GetBloodKits,
    CustomerBloodKitIdValidator,
    getSubcriptionStatus,
    getDna12Subscription,
    createSubscription,
    checkSubscription,
    DiseaseRiskReport,
    DiseaseRiskTab,
    DiseaseRiskTabGroup,
    DiseaseRiskOverview,
    DiseaseRiskSummary,
    getCognitiveSkillsResult,
    getCognitiveDomainResult,
    getDna12Result,
    congenitalPdfResult,
    congenitalCombinedPdfResult,
};

const controller = new AbortController();
const timeout = setTimeout(() => {
    controller.abort();
}, 2000);

async function practitionerDetails() {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
    };

    return fetch(`${config.apiUrl}practitioner/v1/details`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(userActions.updateUserData(data.data))
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);

    // let validate_token = await apiService.TokenValidate({
    //     type: 'TOKEN',
    //     authorizationToken:`Bearer ${sessionStorage.getItem('token')}`
    // })

    // if(validate_token){
    //     return validate_token
    // }else{
    //     window.location.reload("/")
    // }
}


function kitHistory(kit_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ kit_id: kit_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}kit-logestics2/kit-tracking/history`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                store.dispatch(alertActions.error(data?.message || "Error"));
                return null
            }
        });
}

function getSubcriptionStatus(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}payment/subscription/getSubcriptionStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        });
}

function getDna12Subscription(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}payment/subscription/dna12/status`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        });
}

function createSubscription(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}payment/subscription/portal/createportalsubscription`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data.status == "cancel") {
                store.dispatch(alertActions.success("Subscription canceled successfully"))
                return data;
            } else if (data.data) {
                store.dispatch(alertActions.success("Subscription created successfully"))
                return data;
            } else {
                return []
            }
        });
}

async function checkSubscription(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}dna12/helix/status`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data;
            } else {
                return []
            }
        });
}

function requestAccess(practitioner_id, user_id) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: JSON.stringify({ user_id }),
    };


    return fetch(`${config.apiUrl}practitioner/v1/reportAccess`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function search(practitioner_id, item, skip, limit) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ search_item: item, skip, limit })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/searchItem`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

function questionnaireAnswer(practitioner_id, user_id, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id: btoa(user_id), type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/questionnaireAnswers`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     return [];
            // }
            return data
        }).catch(handleUnAuthCatch);
}

async function practionerPortalTab(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ practitioner_id: practitioner_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/report/tab`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

async function practionerPortalTabGroup(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/report/tabgroup`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}
async function practionerPortal(practitioner_id, user_id, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id: btoa(user_id), access: type })
    };

    return fetch(`${config.apiUrl}practitioner/v1/report`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}






async function DiseaseRiskTab(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ practitioner_id: practitioner_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/disease-risk/tab`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

async function DiseaseRiskTabGroup(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/disease-risk/tabgroup`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}
async function DiseaseRiskReport(user_id, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id: btoa(user_id), access: type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/disease-risk`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

async function DiseaseRiskOverview(user_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id, data })
    };

    return fetch(`${config.apiUrl}practitioner-V3/v1/disease-risk-overview`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     throw "user details not found";
            // }
            return data
        }).catch(handleUnAuthCatch);
}
async function DiseaseRiskSummary(type, score) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ access: type, score })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/disease-risk/summary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}





async function CongenitalPortalTab(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/congenital/tab`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}
async function CongenitalPortalTabGroup(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/congenital/tabgroup`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}
async function CongenitalPortal(user_id, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id: btoa(user_id), access: type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/congenital`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
async function CongenitalReportSummary(type, score) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ access: type, score })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/congenital/summary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
async function OverViewCongenitalGet(user_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id, data })
    };

    return fetch(`${config.apiUrl}practitioner-V3/v1/congenital-overview/get`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     throw "user details not found";
            // }
            return data
        }).catch(handleUnAuthCatch);
}







async function DNAResult(practitioner_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/report/dna`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     return [];
            // }
            return data
        }).catch(handleUnAuthCatch);
}

function ReportSummary(practitioner_id, type, score) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ access: type, score })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/report/summary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
function HealthInsightsResult(practitioner_id, user_id, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id, type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/report/healthInsight`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     return [];
            // }
            return data;
        }).catch(handleUnAuthCatch);
}

function EpigeneticResult(practitioner_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/epigeneticReport`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // if (data.data) {
            //     return data.data;
            // } else {
            //     return []
            // }
            return data;
        }).catch(handleUnAuthCatch);
}


async function AccessUsersList(practitioner_id, type = "genetic") {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/AccessUserslist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

async function AccessUserslistByUserData(type, search_item) {
    if (!type) {
        type = "genetic"
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type, search_item })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/AccessUserslistByUserData`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function userManagementGet(condition) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(condition)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/getall`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function userManagementGetByFilter(condition) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(condition)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/getall`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function validateUMEmail(email) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ email: email })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/validate/umemail`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data.data == false) {
                return false
            } else {
                return true
            }
        }).catch(handleUnAuthCatch);
}

function resendUMEmail(email, id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/resend/umemail`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data.data == false) {
                store.dispatch(alertActions.error("Something went wrong"))
                return false
            } else {
                store.dispatch(alertActions.success("Email sent successfully"))
                return true
            }
        }).catch(handleUnAuthCatch);
}

function CreateUser(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/create`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success("Customer registered successfully"))
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        }).catch(handleUnAuthCatch);
}
function createManagementUser(email, type, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type, data })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/createuser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`${camelize(type)} created successfully`))
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        }).catch(handleUnAuthCatch);
}

function EmailValidator(email) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ email })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/validate/email`, requestOptions)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.status == 200) {

                return true;
            } else {
                return false
            }
        }).catch(handleUnAuthCatch);
}

function KitIdValidator(kit_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ kit_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/validate/kitId`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // if(data.status==200){

            //     return true;
            // }else{
            //     return false
            // }
            return data;
        }).catch(handleUnAuthCatch);
}

// not in use
async function CustomerBloodKitIdValidator(body) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body),
    };

    return fetch(`${config.apiUrl}blood-result/customer/kit/status`, requestOptions)
        .then(handleApiResponse)
        .then((data) => {
            return data;
        });
}

async function BloodKitIdValidator(body) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body),
    };

    return fetch(`${config.apiUrl}blood-result/kit/status`, requestOptions)
        .then(handleApiResponse)
        .then((data) => {
            return data;
        });
}

async function CheckAssignBloodKitStatus(body) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body),
    };

    return fetch(`${config.apiUrl}blood-result/kit/assign-status`, requestOptions)
        .then(handleApiResponse)
        .then((data) => {
            return data;
        });
}

async function assignBloodKitToUser(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}blood-result/kit/assign`, requestOptions)
        .then(handleApiResponse)
        .then((data) => {
            if (data.status === 200) {
                store.dispatch(alertActions.success(`Kit Assign Successfully.`));
            } else {
                store.dispatch(alertActions.error(data.message));
            }
            return data;
        });
}

async function CreateBloodKit(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}blood-result/kit/create`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        });
}

async function BulkUploadBloodKit(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}blood-result/kit/bulk-upload`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        });
}

async function GetBloodResult(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}blood-result/result`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        });
}

async function editManagementUser(id, data) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
    };

    let body = { data };

    if (id) {
        body.id = id;
    }

    if (Object.keys(body || {}).length > 0) {
        requestOptions["body"] = JSON.stringify(body);
    }

    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/edit`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            // console.log("edit response data", data)
            if (data.statusCode != 200) {
                let errorMsg = data.error || data.message || "Error in updating details"
                store.dispatch(alertActions.error(errorMsg));
                return false;

            } else {
                store.dispatch(alertActions.success(`Data updated successfully`));
                return data.data;
            }
        }).catch(handleUnAuthCatch);
}

function ListUserByType(practitioner_id, type, limit, skip) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ practitioner_id, type, limit, skip })
    };
    // console.log(requestOptions,'requestOptions')
    return fetch(`${config.apiUrl}practitioner-V3/v1/UserslistByType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function viewManagementUser(type, id) {
    let requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
    };

    if (type !== "self") {
        requestOptions.body = JSON.stringify({ type, id });
    } else {
        requestOptions.body = JSON.stringify({ type });
    }

    return fetch(`${config.apiUrl}practitioner/v1/user/view`, requestOptions)
        .then(handleResponse)
        .then((data) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                return [];
            }
        }).catch(handleUnAuthCatch);
}

function UpdateUserDetails(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/validate/update`, requestOptions)
        .then(handleResponse)
        .then(data => {

            if (data.statusCode == 200) {
                store.dispatch(alertActions.success("User data updated successfully"))

                return data.data;
            } else {
                return false
            }
        }).catch(handleUnAuthCatch);
}

function deleteManagementUser(email, type, id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ email, type, id })
    };
    // console.log(requestOptions,'requestOptions') 

    return fetch(`${config.apiUrl}practitioner/v1/user/deleteuser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`${camelize(type)} deleted successfully`))
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        }).catch(handleUnAuthCatch);
}

function DeleteUser(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/delete`, requestOptions)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success("User deleted successfully"))
                return data.data;
            } else {
                store.dispatch(alertActions.success("User deleted successfully"))
                return []
            }
        }).catch(handleUnAuthCatch);
}


async function accessManagementUser(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/access`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function practitionerList(body) {
    delete body?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/list/practitioner`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function ClinicList(body) {
    delete body?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')
    return fetch(`${config.apiUrl}practitioner-V3/v1/list/clinic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
function PartnerList(body) {
    delete body?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/list/partner`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
function PartnerListNew(email, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/list/partner_new`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
function PartnerListNewNIN(type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/list/partner_new_nin`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}
function FilterdUserList(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/list/user`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function GetAllUsers(body) {
    delete body?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    return fetch(`${config.apiUrl}practitioner-V3/v1/list/getAllCustomer`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                return []
            }
        }).catch(handleUnAuthCatch);
}

function userPractList(email, limit, skip) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ email: email, limit, skip })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/userpractlist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function userPractSearch(email, item, limit, skip) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ email: email, search_item: item, limit, skip })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/userpractsearch`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

function userPractPartner(email, type) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/user/userpartnerlist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

function userPractClinic(email, type, partner = null) {
    if (!email) return
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type: type, partner_id: partner })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/user/usercliniclist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

async function getUserList(partner_id, kit_id, clinic_id = null) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ partner_id, kit_id, clinic_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/assignuserlist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

async function getBloodUserList(email, partner_id, kit_id, clinic_id = null) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ email, partner_id, kit_id, clinic_id })
    };

    return fetch(`${config.apiUrl}blood-result/user/assignlist`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        });
}

async function assignUser(id, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ id, data })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/assignuser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "No data Found";
            }
        }).catch(handleUnAuthCatch);
}

async function releasePDFs(body) {
    delete body?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}practitioner/v1/release/pdf`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("Data in dna/epi/raw report", data)
            if (data.statusCode == 200) {
                setTimeout(() => {
                    store.dispatch(alertActions.success(data.message ? data.message : 'DNA/EPI Report sent successfully'))
                }, 2000)
                // return {
                //     error: false,
                //     message: data.message
                // };
            } else {
                setTimeout(() => {
                    store.dispatch(alertActions.error(data?.message || "DNA/EPI Report generation error. Please contact administrator"))
                }, 2000)
                // return {
                //     error: true,
                //     message: data.message || "Report Generation Error"
                // };
            }
        }).catch(handleUnAuthCatch);
}

async function releaseBloodPdf(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}blood-result/result/pdf`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log("Data in blood report", data)
            if (data.statusCode == 200) {
                setTimeout(() => {
                    store.dispatch(alertActions.success(data.message ? data.message : 'Blood Report sent successfully'))
                }, 2000)
                // return {
                //     error: false,
                //     message: data.message
                // };
            } else {
                setTimeout(() => {
                    store.dispatch(alertActions.error(data?.message || "Blood report generation error. Please contact administrator"))
                }, 2000)
                // return {
                //     error: true,
                //     message: data.message || "Report Generation Error"
                // };
            }
        })
}

async function releaseDNA12ReportPDF(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}practitioner-V3/dna12/release`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log('data dna12 pdf', data)
            if (data.statusCode == 200) {
                setTimeout(() => {
                    store.dispatch(alertActions.success(data?.message ? data.message : 'DNA12 Report sent successfully'))
                }, 2000)
                // return {
                //     error: false,
                //     message: data.message
                // };
            } else {
                    setTimeout(() => {
                        store.dispatch(alertActions.error(data?.message || "DNA12 Report generation error. Please contact administrator"))
                    }, 2000)
                //     return false
                // return {
                //     error: true,
                //     message: data.message || "Report generation error."
                // };
            }
        })
}


function lifeStyleData(condition) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(condition)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/user/lifestyle`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     return []
            // }
            return data;
        }).catch(handleUnAuthCatch);
}
async function TokenValidate(condition) {
    try {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(condition),
        };

        const res = await fetch(`${config.apiUrl}practitioner/token/expiry`, requestOptions);


        if (res.status === 400) {
            const responseBody = await res.json();
            console.log("Response Body:", res.statusText, responseBody);
            return false;
        }


        const data = await res.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        //throw error;
        handleUnAuthCatch(error)
    }
}


// Department

function departmentList(condition) {
    delete condition?.email
    delete condition?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(condition)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/list`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function departmentListSingle(practitioner_id, single_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ single_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/view`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        })//.catch(handleUnAuthCatch);
}

function departmentCreate(practitioner_id, id, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ data })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/create`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`Department created successfully`))
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function departmentUpdate(practitioner_id, id, data) {
    data = {
        partner_id: data.partner_id,
        clinic_id: data.clinic_id,
        department: data.department,
    };
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ id, data })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/edit`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`Department updated successfully`))
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function departmentRemove(practitioner_id, id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/remove`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`Department removed successfully`))
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function departmentSearch(practitioner_id, search_item, limit, skip) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ search_item, limit, skip })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/search`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function departmentListAPI(condition) {
    delete condition?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(condition)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/department/view`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log(data, 'ressssssssssss')
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function UsersCount(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/users/count`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}
function ageRange(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/age/count`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}
function productCount(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/product/count`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function BengagementDevice(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ practitioner_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/engage/device`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function BengagementActive(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ practitioner_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/engage/activemonth`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

async function BIEngagementPlatformUsers(practitioner_id, start_date, end_date) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ start_date, end_date })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/ga/platformuser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

async function BIEngagementActive(practitioner_id, start_date, end_date) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ start_date, end_date })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/ga/activeengagement`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

async function BIEngagementSession(practitioner_id, start_date, end_date) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ start_date, end_date })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/ga/sessionduration`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {

                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}
function TrackingCount(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };
    console.log(requestOptions, 'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/tracking/count`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        })
        .catch(handleUnAuthCatch);
}

function lifestyleCount(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/tracking/lifestyle`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function PractitionerIntro(practitioner_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/intro`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.message;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "practitioner details not found";
            }
        }).catch(handleUnAuthCatch);
}

function ManagementITabs(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/mi/tabs`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "tabs not found";
            }
        }).catch(handleUnAuthCatch);
}

function ManagementIGet(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ practitioner_id })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/mi/get`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}


function ManagementIGetAll(practitioner_id, category) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ gender: "male", category })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/mi/get/all`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function ManagementIGetSingle(practitioner_id, category) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ gender: "male", category })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/mi/get/single`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function GAPlatformUsers(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/ga/platformuser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function GAActiveEngadement(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/ga/activeengagement`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function OverViewPRGet(practitioner_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ practitioner_id, user_id })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/overview/get`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function OverViewPRGetNew(practitioner_id, user_id, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id, data })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/overview/new`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     throw "user details not found";
            // }
            return data
        }).catch(handleUnAuthCatch);
}

function questionScore(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/question/score`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function bioAge(practitioner_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({})
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/bioage/score`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                throw "user details not found";
            }
        }).catch(handleUnAuthCatch);
}

function VirusRisk(practitioner_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/virus/get`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.data){
            //     return data.data;
            // }else{
            //     // eslint-disable-next-line no-throw-literal
            //     throw "user details not found";
            // }
            return data
        }).catch(handleUnAuthCatch);
}
async function createQuestionnaire(practitioner_id, type, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type, data })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/questionnaire/create`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`Question added successfully`))
                return data.data;
            } else {
                store.dispatch(alertActions.error(data?.message || "Error"))
                console.log("else case", data);
                // eslint-disable-next-line no-throw-literal
                throw data;
            }
        })
        .catch(handleUnAuthCatch);
}

async function saveQuestionnaireAnswer(practitioner_id, type, data, user_id, category) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ practitioner_id, type, data, user_id, category })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/questionnaire/save`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                store.dispatch(alertActions.success(`Question added successfully`))
                return data.data;
            } else {
                console.log("else case", data);
                // eslint-disable-next-line no-throw-literal
                throw data;
            }
        })
        .catch(handleUnAuthCatch);
}

function UserResendEmail(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/account/resendemail`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            data = JSON.parse(data.data.body)
            console.log("data", data)
            if (data.statusCode == 200) {
                store.dispatch(alertActions.success(data.message ? data.message : 'Email sent successfully'))
                return data.data;
            } else {
                // if(data.error){
                store.dispatch(alertActions.error(data.message))
                // }
                return []
            }
        }).catch(handleUnAuthCatch);
}

function StepCount(practitioner_id, type, startDate, endDate) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type, startDate, endDate })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/user/stepscount`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                // throw "results are null";
                return []
            }
        }).catch(handleUnAuthCatch);
}

async function QuestionCountWithDate(practitioner_id, type, startDate, endDate) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ type, startDate, endDate })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V2/v1/question/date`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                // throw "results are null";
                return []
            }
        }).catch(handleUnAuthCatch);
}

async function UsageReport(practitioner_id, start_date, end_date, request_type, condition, type, search) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ start_date, end_date, request_type, condition, type, search })
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/usage/get`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.data) {
                return data.data;
            } else {
                // eslint-disable-next-line no-throw-literal
                // throw "results are null";
                return []
            }
        }).catch(handleUnAuthCatch);
}
async function getAppAllowedPartners(partner_id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    return fetch(`${config.apiUrl}V3/allowedPartnerLogins/get?partner_id=${partner_id}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        });
}
async function enableAppAccess(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner/v1/user/appAccess`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.data) {
                store.dispatch(alertActions.success("Sent email to user successfully"))
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        }).catch(handleUnAuthCatch);
}

async function verifyResetLink(token) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token })
    };

    return fetch(`${config.apiUrl}practitioner/v1/user/verifyLink`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}

async function getCountries() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    return fetch(`${config.apiUrl}countries/dna/countries/getall`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        });
}

async function userSetPassword(token, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ password })
    };

    return fetch(`${config.apiUrl}practitioner/v1/user/setPassword`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}
async function completeUserSignup(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}practitioner/v1/user/update`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}

async function AddChildKitToExistingUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}practitioner-V3/v1/add-child-kit`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            // if(data.success){
            //     store.dispatch(alertActions.success(data.message))
            // }else{
            //     store.dispatch(alertActions.error(data.message))
            // }
            return data;
        })
        // .catch((error) => {
        //     console.log("error", error)
        //     store.dispatch(alertActions.error(error.message))
        // })
        .catch(handleUnAuthCatch);
}

async function GetBloodKits(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}blood-result/kit/list`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            if (data.success) {
                return data.data
                // store.dispatch(alertActions.success(data.message))
            } else {
                store.dispatch(alertActions.error(data.message))
                return data;
            }
        })
        .catch((error) => {
            console.log("error", error)
            store.dispatch(alertActions.error(error.message))
            return []
        })
}

async function getAvailablePartners(body) {
    delete body?.practitioner_id
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/partner/get`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        }).catch(handleUnAuthCatch);
}

async function switchPartner(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    };

    return fetch(`${config.apiUrl}practitioner-V2/v1/partner/change`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            if (data.data) {
                return data.data;
            } else {
                if (data.error) {
                    store.dispatch(alertActions.error(data.error))
                }
                return []
            }
        }).catch(handleUnAuthCatch);
}

function handleApiResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            return {
                success: false,
                status: response.status,
                message: data && data.message
            }
        }
        return {
            success: true,
            status: response.status,
            message: data.message || "Success",
            data: data.data
        }
    });
}

async function userLogout() {
    const alert = () => {
        const sessionExpired = sessionStorage.getItem("sessionExpired");

        if (typeof sessionExpired === "boolean" && sessionExpired === false)
            return;

        if (sessionExpired === null) {
            store.dispatch(alertActions.error(`Session Expired`));
        }
    };

    await userService.logout("", alert);
}
async function handleResponse(response) {
    return response.text().then(async (text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                // auto logout if 401 response returned from api
                await userLogout()
            }

            const error =
                Object.keys(data).length > 0
                    ? data
                    : (data && data.message) || response.statusText;
            return error;
        }

        return data;
    });
}


function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toUpperCase();
    }).replace(/\s+/g, ' ');
}


async function handleUnAuthCatch(error) {
    if (error instanceof Error && error.name === "AbortError") {
        // Handle abort errors (e.g., when the request is manually aborted)
        console.error("Request was aborted");
    } else if (error instanceof Error && error.name === "TypeError") {
        // Handle network errors (e.g., when the API endpoint is unreachable)
        console.error("Network error:", error.message);

        //await userLogout()   
    } else {
        // Handle other errors and get the status code from the response
        if (error instanceof Response) {
            const status = error.status;
            console.error(`HTTP Error with status code: ${status}`);
        } else {
            console.error("An error occurred:", error);
        }
    }
}

async function FaceScanResult(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/getFaceAgeResult/${user_id}`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}

async function getCognitiveSkillsResult(user_id, body = {}) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}practitioner-V3/v1/getCognitiveSkillsResult/${user_id}`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}

async function getCognitiveDomainResult(user_id, body = {}) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/v1/getCognitiveDomainResult/${user_id}`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}

async function getDna12Result(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ user_id })
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}dna12/helix/result`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            return data;
        }).catch(handleUnAuthCatch);
}

async function congenitalPdfResult(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/congenital/release`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            console.log("congenital pdf result", data)
            if (data.success) {
                store.dispatch(alertActions.success(data.message || `Report sent successfully`))
                return data;
            } else {
                // eslint-disable-next-line no-throw-literal
                console.log("else case", data);
                store.dispatch(alertActions.error(data?.message || "Error"))
            }
        }).catch(handleUnAuthCatch);
}

async function congenitalCombinedPdfResult(body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(body)
    };
    // console.log(requestOptions,'requestOptions')

    return fetch(`${config.apiUrl}practitioner-V3/congenital-combined/release`, requestOptions)
        .then(handleApiResponse)
        .then(data => {
            console.log("congenital combined pdf result", data)
            if (data.success) {
                store.dispatch(alertActions.success(data.message || `Report sent successfully`))
                return data;
            } else {
                // eslint-disable-next-line no-throw-literal
                console.log("else case", data);
                store.dispatch(alertActions.error(data?.message || "Error in sending report"))
            }
        }).catch(handleUnAuthCatch);
}

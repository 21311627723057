import React from "react";
import { Modal, Form, Spinner, Button } from 'react-bootstrap'
import { apiService } from "../../services/api_service";
import { userActions } from "../../actions"
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

class UserHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: '',
    }
    this.handleModal = this.handleModal.bind()
  }

  async componentDidMount() {
    // console.log("user header", this.props.partner)
    // let partner_id = this.props.partner || 1
    // let partner_id = 1;
    // let logo = await this.logoPick(partner_id)
    // this.setState({ logo: logo })
  }

  handleModal = async () => {

  }

  logoPick(partner_id) {
    let logoWidth;
    switch (partner_id) {
      case 1:
        return { logo: '/images/logo.svg', logowidth: '40%' };
      case 7:
        return { logo: '/images/logos/biosynergy.png', logowidth: '20%' };
      case 13:
        return { logo: '/images/logos/TNLogo.png', logowidth: '25%' };
      case 25:
      case 31:
      case 33:
        return { logo: '/images/logos/hc_logo.png', logowidth: '40%' };
      case 26:
        return { logo: '/images/logos/truepi.png', logowidth: '40%' };
      case 28:
        return { logo: '/images/logos/mamh.png', logowidth: '40%' };
      case 45:
        return { logo: '/images/logos/promake_logo.png', logowidth: '40%' };
      case 47:
        return { logo: '/images/logos/longevity-clinic.jpg', logowidth: '40%' };
      case 46:
        return { logo: '/images/logos/longevity-logo.png', logowidth: '40%' };
      case 48:
        return { logo: '/images/logos/lifetime_logo.png', logowidth: '40%' };
      case 11:
        return { logo: '/images/logos/formulahealth_logo.png', logowidth: '40%' };
      case 15:
        return { logo: '/images/logos/bodyology_logo.png', logowidth: '40%' };
      case 50:
        return { logo: '/images/logos/eterna_life.png', logowidth: '40%' };
      case 51:
        return { logo: '/images/logos/bevivo.png', logowidth: '40%' };
      default:
        return { logo: '/images/logo.svg', logowidth: '40%' };

    }
  }

  render() {
    console.log("partner", this.props);
    let partner_id = this.props.partner_id;
    let logoData = this.logoPick(this.props.partner_id)
    return (
      <>
        <header className="user-header-reset">
          <div className="container-wrp">
            <div className="row justify-content-between">
              {partner_id ? (
                <div className="col-4 col-md-3" >
                  <img className="img-fluid" src={logoData.logo} alt="Logo" style={{ width: logoData.logowidth }}></img>
                </div>
              ) : null}
              <div className="col-auto col-md-auto d-flex align-items-center">
                <button className="menuicon d-md-none"><img src="/images/hamburgericon.svg" alt="Menu"></img></button>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
};

export default UserHeader;

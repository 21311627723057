import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { userActions, alertActions } from '../../../actions';
import Loader from "../../../components/Loader/Loader";
// import { history } from "../../helpers";


class SignIn extends React.Component {
    constructor(props){
        super(props)
        this.props.logout(this.props.history);

        this.state = {
            email: "",
            password: "",
            submitted: false,
            hide: true,
            loading: false,
            emailError: false,
            passwordError: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUnhidePassword = this.handleUnhidePassword.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
        let partner_id = this.props.partner
        // set default color
        if (partner_id == '7') {
            document.getElementById("bodyID").style.setProperty('--themecolor', '#000000');
            document.getElementById("bodyID").style.setProperty('--themefontcolor', '#000000');
            document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#a6a6a6');
            document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#e6e6e6');
            document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#818181');
            document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
        } if (partner_id == '45') {
            document.getElementById("bodyID").style.setProperty('--themecolor', '#080B37');
            document.getElementById("bodyID").style.setProperty('--themefontcolor', '#080B37');
            document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#636ce9');
            document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#d2d4f9');
            document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#3642e2');
            document.getElementById("bodyID").style.setProperty('--filterSvg', ' invert(0%) sepia(3%) saturate(6%) hue-rotate(50deg) brightness(30%) contrast(100%)');
        }
        else if (partner_id === 46) {
            document
              .getElementById("bodyID")
              .style.setProperty("--themecolor", "#2c2c2c");
            document
              .getElementById("bodyID")
              .style.setProperty("--themefontcolor", "#2c2c2c");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteThemecolor", "#b3b3b3");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteBackgroundColor", "#f2f2f2");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteButtonBackground", "#e6e6e6");
            document
              .getElementById("bodyID")
              .style.setProperty("--navtext", "#ffffff");
            document
              .getElementById("bodyID")
              .style.setProperty("--headpadding", "22px 0px");
          }
          else if (partner_id === 48) {
            document
              .getElementById("bodyID")
              .style.setProperty("--themecolor", "#65C0B6");
            document
              .getElementById("bodyID")
              .style.setProperty("--themefontcolor", "#65C0B6");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteThemecolor", "#c9e9e5");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteBackgroundColor", "#edf8f6");
            document
              .getElementById("bodyID")
              .style.setProperty("--liteButtonBackground", "#b7e1dc");
            document
              .getElementById("bodyID")
              .style.setProperty("--headpadding", "9px 0px");
           }
              else if (partner_id === 11) {
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themecolor", "#76D3CA");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themefontcolor", "#76D3CA");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteThemecolor", "#c5ece8");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteBackgroundColor", "#ecf9f7");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteButtonBackground", "#d8f3f0");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--headpadding", "22px 0px");
              }
              else if (partner_id === 15) {
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themecolor", "#243144");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themefontcolor", "#243144");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteThemecolor", "#bcc9dc");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteBackgroundColor", "#eef1f6");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteButtonBackground", "#cdd6e5");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--headpadding", "12px 0px");
              }
              else if (partner_id === 50) {
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themecolor", "#05aaff");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themefontcolor", "#05aaff");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteThemecolor", "#b3e6ff");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteBackgroundColor", "#cceeff");
                  document
                  .getElementById("bodyID")
                  .style.setProperty("--navtext", "#ffffff");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteButtonBackground", "#cdd6e5");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--headpadding", "15px 0px");
              }
              else if (partner_id === 51) {
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themecolor", "#b34944");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--themefontcolor", "#b34944");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteThemecolor", "#f8eded");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteBackgroundColor", "#f8eded");
                  document
                  .getElementById("bodyID")
                  .style.setProperty("--navtext", "#ffffff");
                document
                  .getElementById("bodyID")
                  .style.setProperty("--liteButtonBackground", "#FCFCFA");
               
              }
        
        else {
            document.getElementById("bodyID").style.setProperty('--themecolor', '#09a79e');
            document.getElementById("bodyID").style.setProperty('--themefontcolor', '#09a79e');
            document.getElementById("bodyID").style.setProperty('--liteThemecolor', '#09a79e69');
            document.getElementById("bodyID").style.setProperty('--liteBackgroundColor', '#09a79e17');
            document.getElementById("bodyID").style.setProperty('--liteButtonBackground', '#0ac2b9');
            document.getElementById("bodyID").style.setProperty('--filterSvg', '');
        }

        if(this.props.alert.message){
            setTimeout(()=>{
                this.props.clearAlerts();
            }, 5000)
        }
    }

    handleChange(e){
        e.preventDefault()
        const {name, value} = e.target;
        this.setState({ [name]: value });
    }

    handleEmail(e){
        e.preventDefault()
        const {name, value} = e.target;
        this.setState({ email: value, emailError: false });
    }

    handlePassword(e){
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if(this.state.email && this.state.email.match(regexEmail)){
            e.preventDefault()
            const {name, value} = e.target;
            this.setState({ password: value, emailError: false, passwordError: false });
        }else{
            if(this.state.email){
                this.setState({emailError: true, password: e.target.value, passwordError: false})
            }else{
                this.setState({emailError: false, password: e.target.value, passwordError: false})
            }
            
        }
        
    }

    handleKeyPress(e){
        if(e.charCode === 13){
            if(this.state.email && this.state.password){
                this.handleSubmit()
            }else{
                if(!this.state.email){
                    this.setState({passwordError: true, emailError:true})
                }else{
                    this.setState({passwordError: true })
                }
            }
        }
    }

    async handleSubmit(e){
        if (this.state.password) {
          let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
          if (!this.state.email || !this.state.email.match(regexEmail)) {
            this.setState({ emailError: true });
            return;
          }
         this.setState({ submitted: true, passwordError: false });
          const { email, password } = this.state;
          if (email && password) {
            let response = await this.props.login(
              this.props.history,
              email,
              btoa(password),
              this.props.partner
            );

            if (!response) {
              setTimeout(() => {
                this.setState({ submitted: false, passwordError: false });
              }, 1000);
              console.log("this.props", this.props);
            }
          }
        } else {
          if (!this.state.email) {
            this.setState({ passwordError: true, emailError: true });
          } else {
            this.setState({ passwordError: true });
          }
        }
        
    }
    handleUnhidePassword(){
        this.setState({hide: !this.state.hide})
    }

    componentDidUpdate(){
        if(this.props.alert.message){
            setTimeout(()=>{
                this.props.clearAlerts();
            }, 5000)
        }
    }

    handleClose = () => {
        this.props.clearAlerts();
    }
    render() {
        const {loggingIn, alert, partner } = this.props;
        const { email, password, submitted, loading, hide, emailError, passwordError } = this.state;

        return (
            <div className="formPart signinForm">
                {alert.message &&
                    <div className={`alert ${alert.type}`} style={{marginBottom: 0}}>
                        {alert.message}
                        <button className="close" onClick={this.handleClose} >x</button>
                    </div>
                }
                <Loader loading={loading} />
                <h1>Log In</h1>
                <h6>Your Genetic Health Toolkit</h6>
                <Form>
                    <Form.Group className="sign-field">
                        <Form.Control placeholder="Email" type="email" name="email" value={email} onChange={this.handleEmail} onKeyPress={this.handleKeyPress} required />
                        <Form.Text className="text-muted">
                            {emailError? "Please Enter a valid Email": null}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="sign-field">
                        <div className="passwordfeild">
                            <Form.Control placeholder="Password" type={this.state.hide?"password": "text"} name="password" value={password} onChange={this.handlePassword} onKeyPress={this.handleKeyPress}  required />
                            <span className="eyeicon" onClick={this.handleUnhidePassword}>
                                <img style={{width: "inherit"}}  src={hide?"/images/eyeicon.svg":"/images/eye.svg"}></img>
                            </span>
                        </div>
                        <Form.Text className="text-muted">
                            {passwordError? "Please Enter the Password": null}
                        </Form.Text>
                    </Form.Group>
                    <div className="text-right">
                {partner == '7' ?
                  <Link className="forgotlink" to="/bio-synergy/ForgotPassword">
                    Forgot Password?
                  </Link>
                  : partner == '50' ?
                    <Link className="forgotlink" to="/eterna-life/ForgotPassword">
                      Forgot Password?
                    </Link>
                     : partner == '48' ?
                     <Link className="forgotlink" to="/lifetime-health/ForgotPassword">
                       Forgot Password?
                     </Link>
                      :
                    <Link className="forgotlink" to="/ForgotPassword">
                      Forgot Password?
                    </Link>
                }
                    </div>
                    <Button className="continuebtn" 
                    disabled={this.state.submitted}
                    onClick={() => {
                        if(!submitted){
                            this.handleSubmit()
                        }
                    }} username = {this.state.email}>
                        LOGIN {this.state.submitted && <Spinner className='spinnerClass' animation="border" variant="light" size="sm" style = {{float : 'center'}} /> }
                    </Button>
                </Form>
            </div>
        );
    }
};

function mapState(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {loggingIn, alert};
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    login: userActions.login,
    logout: userActions.logout
}
export default connect(mapState, actionCreators)(SignIn);

import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";
import { EyeIcon } from "../Icons/resetPassIcon";

class SetPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    // this.props.logout(this.props.history);

    this.state = {
      email: "",
      // code: "",
      newpassword: "",
      confirmpassword: "",
      hide: true,
      emailError: false,
      passwordError: false,
      passwordFormat: false,
      hide1: true,
      confirmError: false
    }
    this.handleChange = this.handleChange.bind(this);
    // this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleUnhidePassword = this.handleUnhidePassword.bind(this);
    this.handleUnhideConfirmPass = this.handleUnhideConfirmPass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePassword(e) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    if (this.state.email && this.state.email.match(regexEmail)) {
      e.preventDefault()
      const { name, value } = e.target;
      this.setState({ newpassword: value, emailError: false, passwordError: false, confirmError: false });
    } else {
      this.setState({ emailError: true })
    }

  }
  // handleCodeChange(e) {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value }, () => {
  //     if (value !== "") {
  //       this.setState({ codeError: false });
  //     } else {
  //       this.setState({ codeError: true })
  //     }
  //   });
  // }
  handlePasswordChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      // Check password is empty
      if (this.state.newpassword == "") {
        this.setState({ passwordError: true });
        return;
      } else {
        this.setState({ passwordError: false });
      }
      // Password format check
      let passwordregx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/
      if (this.state.newpassword.match(passwordregx)) {
        this.setState({ passwordFormat: false })
      } else {
        this.setState({ passwordFormat: true })
        // return;
      }
      if (this.state.confirmpassword && this.state.newpassword != this.state.confirmpassword) {
        this.setState({ confirmError: true })
      } else {
        this.setState({ confirmError: false })
      }
    });

  }

  handleConfirmPasswordChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      // Confirm password match check
      if (this.state.newpassword !== this.state.confirmpassword) {
        this.setState({ confirmError: true });
        // return;
      } else {
        this.setState({ confirmError: false });
      }
    });
  }

  handleKeyPress(e) {
    // let passwordregx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,25}$/
    // if(this.state.newpassword && this.state.newpassword.match(passwordregx)){
    //   this.setState({passwordFormat: false})
    // }else{
    //   if(this.state.newpassword !== ""){
    //     this.setState({passwordFormat: true})
    //   }else{
    //     this.setState({passwordFormat: false})
    //   }
    // }
    if (e.charCode === 13) {
      if (this.state.newpassword && this.state.confirmpassword && !this.state.passwordFormat) {
        e.preventDefault()
        this.handleSubmit()
      }
      // else{
      //   if(!this.state.newpassword ){
      //       this.setState({passwordError: true })
      //   }else{
      //     if(!this.state.confirmpassword || this.state.newpassword !== this.state.confirmpassword){
      //       this.setState({passwordError: false, confirmError: true })
      //     }else {
      //       this.setState({passwordError: false, confirmError: false })
      //     }
      //   }
      // }
    }
  }

  handleUnhidePassword() {
    this.setState({ hide: !this.state.hide })
  }
  handleUnhideConfirmPass() {
    this.setState({ hide1: !this.state.hide1 })
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  async handleSubmit(e) {
    let passwordError = false, confirmError = false
    if (this.state.newpassword == "") {
      passwordError = true;
      // this.setState({ passwordError: true });
    }
    if (this.state.newpassword !== this.state.confirmpassword) {
      confirmError = true;
    }

    this.setState({ passwordError, confirmError });

    if (passwordError || confirmError || this.state.passwordFormat) {
      return;
    }
    // if (this.state.newpassword === this.state.confirmpassword && !this.state.passwordFormat) {
      const  { email, newpassword, confirmpassword } = this.state;
    //   const {email} = this.props;

      await this.props.handleSubmit({email, newpassword, confirmpassword});
    // } else {
    //   if (this.state.confirmpassword) {
    //     this.setState({ confirmError: true })
    //   } else {
    //     this.setState({ passwordError: true })
    //   }
    // }
  }

  componentDidMount() {
    this.setState({ email: this.props.email });
  }
 
    render() {
        const { newpassword, confirmpassword, hide, email,
             emailError, passwordError, confirmError, passwordFormat, hide1 } = this.state;
        return (
            <>
                <h1>Set Password</h1>
                <h6>Please Enter your new password for the app</h6>
                <Form>
                    <Form.Group className="sign-field">
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control placeholder="Email" type="text" name="email" value={email} disabled required />
                        <Form.Text className="text-muted">
                            {emailError ? "Please Enter a valid Email" : null}
                        </Form.Text>
                    </Form.Group>
                    {/* <Form.Group className="sign-field">
                        <Form.Control placeholder="Verification Code" type="text" name="code" value={code} autoComplete="cc-name" required onChange={this.handleCodeChange} />
                        <Form.Text className="text-muted">
                            {codeError ? "Please Enter a valid Verification Code" : null}
                        </Form.Text>
                    </Form.Group> */}
                    <Form.Group className="sign-field">
                        {/* <Form.Label>Password</Form.Label> */}
                        <div className="passwordfeild">
                            <Form.Control placeholder="New Password" type={this.state.hide ? "password" : "text"} name="newpassword" value={newpassword} onChange={this.handlePasswordChange} onKeyPress={this.handleKeyPress} required />
                            <span className="eyeicon" onClick={this.handleUnhidePassword}>
                               <EyeIcon></EyeIcon>
                            </span>
                        </div>
                        <Form.Text className="text-muted">
                            {passwordError ? "Please Enter the Password" : null}
                            {passwordFormat ? "Must contain at least one number and one uppercase and lowercase letter, and between 8 and 25 characters": null}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="sign-field">
                        {/* <Form.Label>Confirm Password</Form.Label> */}
                        <div className="passwordfeild">
                            <Form.Control placeholder="Confirm Password" type={this.state.hide1 ? "password" : "text"} name="confirmpassword" value={confirmpassword} onChange={this.handleConfirmPasswordChange} onKeyPress={this.handleKeyPress} required />
                            <span className="eyeicon" onClick={this.handleUnhideConfirmPass}>
                               <EyeIcon></EyeIcon>
                            </span>
                        </div>
                        <Form.Text className="text-muted">
                            {confirmError ? "Password Didn't match" : null}
                        </Form.Text>
                    </Form.Group>
                    <Button className="submitbtn" onClick={this.handleSubmit}
                      disabled={
                        !this.props.email  || !this.props.token || 
                        this.props.submitted
                      }
                    >
                        Set Password
                    </Button>
                </Form>
            </>
        );
    }
};


export default SetPasswordForm;

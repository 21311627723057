export const getThemeColor = (partnerId) => {
    const themeColor = (partner) => {
      switch (partner) {
        case 25:
            case 31:
            case 33:
              return '#c38adb';
            case 7:
              return '#000000';
            case 26:
              return '#398cb2';
            case 28:
              return '#00CBB2';
            case 13:
              return '#17629e';
            case 45:
              return '#080B37';
            case 46:
              return '#2c2c2c';
            case 48:
              return '#65C0B6';
            case 11:
              return '#76D3CA';
            case 15:
              return '#243144';
            case 50:
              return '#05aaff';
            case 51:
              return '#B34944';
            default:
              return '#3498db'; 
      }
    };
  
    const color = themeColor(partnerId);
    document.documentElement.style.setProperty('--themecolor', color)
    return color;
  };
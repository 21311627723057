import React, { useState } from "react";
import PageHead from "../../components/PageHead/PageHead";
import TabItems from "../../components/TabItems/TabItems";
import CongenitalSummary from "./CongenitalSummary";
import CongenitalDetails from "./CongenitalDetails";
import SelectUser from '../../components/PageHead/SelectUser'
import OverviewDetails from "./OverviewDetails";
import { apiService } from '../../services/api_service';
import Select from 'react-select';
import { Spinner, Modal } from "react-bootstrap";
import { alertActions, currentActions } from "../../actions";
import WarningIntro from "./warningIntro";
import Disclaimer from "./disclaimer";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
import { handleDownloadFile } from "../../../src/helpers/handleDownloadFile"

class CongenitalReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: false,
      users_list: [],
      congenital_list: [],
      compare_congenital_list: [],
      congenital_report: [],
      congenital_sum: 0,
      summary: [],
      currentUser: null,
      currentUserName: null,
      compareUser: null,
      compareUserName: null,
      message: 'Please select patients from the above dropdown',
      tabs: [],
      practitioner_id: this.props.user?.id,
      category: '',
      subCategory: '',
      select: { label: 'Overview', value: 1 },
      error_display: false,
      dropdown: [
        { label: 'Overview', value: 1 },
        { label: 'Details', value: 2 },
        { label: 'Summary', value: 3 }
      ],
      access: {},
      overview: [],
      overview_head: [],
      tab_groups: [],
      compare_tab_groups: [],
      compare_tabs: [],
      showSelect: false,
      showIntro: true,
      showDisclaimer: true,
      title: "",
      showPdfButton: false,
      pdfButtonTitle: ""
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.user?._id && nextProps.user?._id !== this.state._id) {
      this.setState({ loader: true });
      try {
        // let intro = false;
        // let user = nextProps.user;
        let practitioner_details = nextProps.user//await apiService.CongenitalDetails();
        // let user_details = await apiService.AccessUsersList(
        //   Buffer.from(String(practitioner_details._id)).toString("base64")
        // );
        // if (user.email) {
        //   intro = true;
        // }

        this.setState({
          //users_list: user_details,
          practitioner_id: Buffer.from(
            String(practitioner_details._id)
          ).toString("base64"),
          access: practitioner_details.access_controls.Congenital_report,
          loader: false,
          //intro,
          //show: true,
          ...nextProps.user
        });
      } catch (error) {
        console.log("error", error);
      }
    }

  }

  async GetTabGroups(user_id, user_name) {
    if (!this.props.user?._id || !user_id) return
    try {
      this.setState({ loader: true })
      if (user_id == 'select') {
        this.setState({
          loader: false,
          tab_groups: [],
          tabs: [],
          message: 'Please select patients from the above dropdown',
          currentUser: null,
          compareUser: null,
          currentUserName: null,
          compareUserName: null,
          compare_overview: null,
          compare_congenital_list: null,
          error_display: false,
          showPdfButton: false,
          pdfButtonTitle: "",
          pdfType: 'single'
        });
        return;
      }
      let result = await apiService.CongenitalPortalTabGroup(
        btoa(this.props.user?._id),
      );
      if (result.length > 0) {
        let tabs = result.map(t => t.title);

        // Get the first tab data
        this.setState({
          tab_groups: result,
          tabs,
          loader: false,
          currentUser: user_id,
          currentUserName: user_name,
          // showPdfButton: !this.state.error_display,
          pdfButtonTitle: this.state.compareUser ? "Generate Combined PDF" : "Export Congenital PDF",
          pdfType: this.state.compareUser ? 'combined' : 'single'
        }, () => {
          this.getOverview(result[0]);
        });
      }
    } catch (error) {
      this.setState({ loader: false });
      console.log("error", error);
    }
  }

  async GetComparisonTabGroups(user_id, user_name) {
    if (!this.props.user?._id || !user_id) return
    try {
      this.setState({ loader: true })
      if (user_id == 'select') {
        this.setState({
          loader: false,
          compare_tab_groups: [],
          compare_tabs: [],
          compareUser: null,
          pdfButtonTitle: "Export Congenital PDF",
          pdfType: "single",
          compareUserName: null,
          compare_overview: null,
          compare_congenital_list: null
        });
        return;
      }

      let result = await apiService.CongenitalPortalTabGroup(user_id);

      if (result.length > 0) {
        let tabs = result.map(t => t.title);

        // Get the first tab data
        this.setState({
          compare_tab_groups: result,
          compare_tabs: tabs,
          loader: false,
          compareUser: user_id,
          pdfButtonTitle: "Generate Combined PDF",
          pdfType: "combined",
          compareUserName: user_name
        }, () => {
          this.getCompareOverview(result[0]);
        });
      }
    } catch (error) {
      this.setState({ loader: false });
      console.log("error", error);
    }
  }

  async getOverview(tab) {
    this.setState({ loader: true })

    const { currentUser, currentUserName, practitioner_id } = this.state;
    const user_id = Buffer.from(String(currentUser)).toString('base64');
    let children = tab.children;
    const data = tab.children.map(c => c.key);

    let overview = await apiService.OverViewCongenitalGet(user_id, data)
    if (!overview.success) {
      // && overview.message == 'user not found') {
      let message = overview.message || 'No Results found'
      if (overview.status == 500)
        message = "Error in generating report"
      this.setState({ loader: false, message, error_display: true })
      return;
    } else {
      overview = overview.data;
    }
    if (overview && overview.length > 0) {
      let overviewData = []
      let availableTabs = overview.map(o => o.key)
      children = children.filter(ch => availableTabs.includes(ch.key))

      overview.map(overviewItem => {
        overviewData.push({
          "type": overviewItem.key,
          "title": overviewItem.outcomeTitle,
          "range": [0],
          "score": overviewItem.outcome,
          "summary": overviewItem.outcomeBody,
          "total": [0, 2],
          "alleles": overviewItem.alleles
        })
      });
      this.setState({
        category: tab.title,
        subCategory: '',
        overview: overviewData,
        congenital_list: children,
        showSelect: false,
        select: { label: 'Overview', value: 1 },
        error_display: false,
        loader: false,
        title: tab.title.replace("_", " ").toLocaleUpperCase(),
        showPdfButton: true
      });
    } else {
      this.setState({
        loader: false,
        message: "User doesn't have any result",
        error_display: true
      });
    }
  }

  async getCompareOverview(tab) {
    this.setState({ loader: true })

    const { compareUser, compareUserName, practitioner_id } = this.state;
    const user_id = Buffer.from(String(compareUser)).toString('base64');
    let children = tab.children;
    const data = tab.children.map(c => c.key);

    let overview = await apiService.OverViewCongenitalGet(user_id, data)

    if (!overview.success) {
      // && overview.message == 'user not found') {
      const message = overview.message || 'No Results found'
      this.setState({ loader: false, message, error_display: true, showPdfButton: false })
      return;
    }
    else {
      overview = overview.data;
    }
    if (overview && overview.length > 0) {
      let overviewData = []
      let availableTabs = overview.map(o => o.key)
      children = children.filter(ch => availableTabs.includes(ch.key))
      overview.map(overviewItem => {
        overviewData.push({
          "key": overviewItem.key,
          "type": overviewItem.key,
          "title": overviewItem.outcomeTitle,
          "range": [0],
          "score": overviewItem.outcome,
          "summary": overviewItem.outcomeBody,
          "total": [0, 2],
          "alleles": overviewItem.alleles
        })
      });

      this.setState({
        compare_overview: overviewData,
        compare_congenital_list: children,
        error_display: false,
        loader: false,
        showPdfButton: true
      });
    } else {
      this.setState({
        loader: false,
        message: "User doesn't have any result",
        error_display: true,
        showPdfButton: false
      });
    }
  }

  async getCongenitalReport(category) {
    const { practitioner_id, currentUser } = this.state;
    let result = await apiService.CongenitalPortal(currentUser, category)

    if (result) {
      let summary = {
        "key": result.key,
        "type": category,
        "title": result.outcomeTitle,
        "range": [0],
        "score": result.outcome,
        "summary": result.outcomeBody,
        "total": [0, 2],
        "alleles": result.alleles,
        "intro": result.intro
      };

      this.setState({ congenital: result, congenital_sum: result.outcome, error_display: false, summary });
    } else {
      this.setState({ message: "User doesn't have any result", error_display: true, congenital: [], summary: [] });
    }

  }

  async changeCategory(category) {
    const { tab_groups } = this.state;
    let selectedTab = tab_groups.find(t => t.title == category);

    if (selectedTab.children && selectedTab.children.length) {
      await this.getOverview(selectedTab);
    }
  }

  async selectAlgo(subCategory) {
    this.setState({ loader: true })
    await this.getCongenitalReport(subCategory.key);
    this.setState({
      showSelect: true,
      select: { label: 'Summary', value: 3 },
      subCategory: subCategory.key,
      loader: false,
      title: subCategory.key.replace("_", " ").toLocaleUpperCase()
    });
  }

  handleSelect(selected) {
    let updateState = { select: selected };

    if (selected.value == 1) {
      updateState.showSelect = false;
      this.handleTitle("Congenital Report");
    }
    this.setState(updateState);
  }

  async AcceptIntro() {
    this.props.removeCurrentUser()
    setTimeout(() => {
      this.setState({ showIntro: false });
    }, 0);
  }

  RejectIntro() {
    const { history } = this.props;
    if (history) history.goBack();
  }

  async AcceptDisclaimer() {
    this.props.removeCurrentUser()
    setTimeout(() => {
      this.setState({ showDisclaimer: false });
    }, 0);
  }

  RejectDisclaimer() {
    const { history } = this.props;
    if (history) history.goBack();
  }

  handleTitle() {
    this.setState({ title: "" })
  }

  handleViewExample() {
    const sourcePath = "pdf/DNA_reports/congenitalRisk.pdf";
    const destinationPath = "download";
    const fileName = "sample_congenital_risk_report.pdf";
    handleDownloadFile(sourcePath, destinationPath, fileName);
  }

  async handleExportCongenitalPDF(data) {
    try {
      if (this.state.pdfType === "single") {
        console.log("Single PDF")
        let result = await apiService.congenitalPdfResult(data);
      } else {
        console.log("Combined PDF")
        let result = await apiService.congenitalCombinedPdfResult(data);
      }
    } catch (error) {
      console.log("Error in congenital pdf", error)
      throw error;
    }
  }

  render() {
    const { loader, tabs, users_list, congenital_list, compare_congenital_list, category, subCategory,
      congenital_report, dropdown, select, message, congenital_sum, currentUser, compareUser, currentUserName, compareUserName,
      error_display, summary, overview, compare_overview, showSelect, showIntro, showDisclaimer, title,
      showPdfButton, pdfButtonTitle, pdfType } = this.state;
    const introModal = (
      <Modal show={this.state.showIntro} centered className="trackingModal" size="sm">
        <Modal.Header >
          <Modal.Title>WARNING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WarningIntro></WarningIntro>
          <div className="row">
            <div className="col-12 col-md-8 mt-4">
              {(this.state.partner_id !== 50 && this.state.partner_id !== 51) ?
                <button className={"infobtn-view"} onClick={this.handleViewExample}>View Example Report</button>
                : ''}
            </div>
            <div className="col-12 col-md-4 text-end mt-4">
              <button className={"continuebtn-yes width-100px d-inline m-1"} onClick={() => this.AcceptIntro()}>Yes</button>
              <button className={"continuebtn-no width-100px d-inline m-0"} onClick={() => this.RejectIntro()}>No</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
    const disclaimerModal = (
      <Modal show={this.state.showDisclaimer} centered className="trackingModal" size="sm">
        <Modal.Header >
          <Modal.Title>Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Disclaimer></Disclaimer>
          <button className={"continuebtn-yes"} onClick={() => this.AcceptDisclaimer()}>Yes</button>
          <button className={"continuebtn-no"} onClick={() => this.RejectDisclaimer()}>No</button>
        </Modal.Body>
      </Modal>
    );
    return (
      <>
        <div className="dnaReport-wrp">
          {this.state.showIntro ? introModal : null}
          {this.state.showDisclaimer && this.state.showIntro ? disclaimerModal : null}

          {!this.state.showIntro && !this.state.showDisclaimer && !this.state.show && (
            <PageHead
              title="Congenital Conditions"
              UserList={users_list}
              DetailsGetAPI={this.GetTabGroups.bind(this)}
              page={"CR"}
              handleTitle={this.handleTitle.bind(this)}
              showReportButton={showPdfButton}
              reportButtonTitle={pdfButtonTitle}
              pdfType={pdfType}
              compareUser={compareUser}
              compareUserName={compareUserName}
              reportButtonCallback={this.handleExportCongenitalPDF.bind(this)}
            ></PageHead>
          )}

          <TabItems
            tab={tabs}
            categorySelector={this.changeCategory.bind(this)}
            category={category.replace(/_/g, " ")}
          ></TabItems>

          {/* <SelectionsBar></SelectionsBar> */}
          {/* use this "SelectionsBar" component with below hard coded data */}
          <div className="selectionBar">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-sm-auto">
                <h3>
                  {/* <img src="/images/immunity.svg"></img>  */}
                  {title ? title.replace(/_/g, " ") : "Congenital Report"}
                </h3>
              </div>
              {
                showSelect ?
                  (
                    <div className="col-12 col-sm-auto">
                      <Select
                        onChange={(res) => this.handleSelect(res)}
                        defaultValue={dropdown[0]}
                        value={select}
                        options={dropdown}
                        menuPlacement="auto"
                        isSearchable={false}
                        className="customSelect-wrp"
                        classNamePrefix="customSelect"
                      />
                    </div>
                  )
                  :
                  (
                    currentUser && overview.length > 0 ?
                      <SelectUser
                        DisplayText={"Compare with:"}
                        CompareDetailsGetAPI={this.GetComparisonTabGroups.bind(this)}
                        onUserClear={() => {
                          this.setState({
                            compareUser: null,
                            compareUserName: null,
                            compare_overview: null,
                            compare_congenital_list: null,
                            showPdfButton: !error_display,
                          });
                        }}
                      />
                      :
                      null
                  )
              }
            </div>
          </div>

          <div className="practitionerReport">
            {
              loader ?
                (
                  <Spinner
                    className="spinnerClass"
                    animation="border"
                    variant="info"
                    style={{
                      marginLeft: "50%",
                      padding: "20px",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  />
                )
                :
                currentUser && !error_display ?
                  (
                    select.value === 3 ? (
                      <CongenitalSummary
                        num={congenital_sum}
                        summary={summary}
                      />
                    )
                      :
                      select.value === 2 ? (
                        <CongenitalDetails data={summary.alleles} />
                      )
                        :
                        <OverviewDetails
                          name_one={currentUserName}
                          name_two={compareUserName}
                          overview={overview}
                          tabs={congenital_list}
                          compare_overview={compare_overview}
                          compare_tabs={compare_congenital_list}
                          categorySelector={this.selectAlgo.bind(this)}
                        />
                  )
                  :
                  (
                    <h5 style={{ textAlign: "center", padding: "20px" }}>
                      {message}
                    </h5>
                  )
            }
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, user } = state.authentication;
  return { loggingIn, user };
}

const mapDispatchToProps = {
  success: alertActions.success,
  addCurrentUser: currentActions.add,
  removeCurrentUser: currentActions.remove
};

// withRouter(connect(mapStateToProps, mapDispatchToAction)(App));
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CongenitalReport);

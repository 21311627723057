
import {colorCodeSummaryReport} from '../constants/color-code'

export function getColorCode(num) {
    // console.log("num",num,colorCodeSummaryReport.two)
    switch (num) {
      case 0:
        return colorCodeSummaryReport.zero 
      case 1:
        return colorCodeSummaryReport.one;
      case 2:
        return colorCodeSummaryReport.two;
      default:
        return colorCodeSummaryReport.default;
    }
  }

import React from 'react';
import { Bar } from 'react-chartjs-2';
import GraphFilter from "./GraphFilter/GraphFilter";
import { alertActions } from '../../actions';
import { connect } from 'react-redux';




const BarGrapgh = ({data,labels,xAxis,DetailsGetAPI,start_date,end_date,selected_type, ...props}) => {
  if(data === undefined){
    data = [50, 200, 600, 150, 100, 80, 200, 300, 150, 100, 80]
   
  }
  if(labels=== undefined){
    labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  }
  let partner_id = props.user?.partner_id
  let graph_color = '#09a79e69'
  if (partner_id == 25 || partner_id == 31 || partner_id == 33) {
    graph_color = '#c38adb'
  }else if (partner_id == 7) {
    graph_color = '#000000'
  } else if (partner_id == 26) {
    graph_color = '#398cb2'
  } else if (partner_id == 28) {
    graph_color = '#00CBB2'
  } else if(partner_id == 13){
    graph_color = '#17629e'
  }else if (partner_id == 45) {
    graph_color = '#080B37'
  }else if (partner_id === 46) {
    graph_color = '#2c2c2c'
  }else if (partner_id === 48) {
    graph_color = '#65C0B6'
  }else if (partner_id === 11) {
    graph_color = '#76D3CA'
  }else if (partner_id === 15) {
    graph_color = '#243144'
  } else if (partner_id === 50) {
    graph_color = '#05aaff'
  }
  else if (partner_id === 51) {
    graph_color = '#B34944'
  }

  
  let data1 = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: graph_color,      
      },
  
    ],
  }
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: false
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 14
          }
        },
        title: {
          color: '1E1E1E',
          display: true,
          text: xAxis
        }
  
      },
      x: {
        title: {
          color: '1E1E1E',
          display: true,
          text: xAxis
        }
      }
    }
  };

  return(
        <>
            <div className='graph-wrp'>

              <GraphFilter
                type='bar'
                label1=''
                label2=''
                label3=''
                DetailsGetAPI={DetailsGetAPI}
                start_date={start_date}
                end_date={end_date}
                selected_type={selected_type}
              
              ></GraphFilter>
              <div className="barchart-wrp cmnchart-wrp">
                <Bar data={data1} height="390" options={options} />
              </div>
              
            </div>
          </>
  )
  
  }


  function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return {loggingIn, user};
  }

const actionCreators = {
    success: alertActions.success
  }

export default connect(mapState, actionCreators)(BarGrapgh)


